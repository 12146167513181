import React, { useEffect, useState } from "react";
import Side_bar from "./Side_bar";
import NavBar from "./../NavBar";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { userAxios } from "../../config/config";
import { showToast } from "../../utils/toast";
import { Button } from "react-bootstrap";

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import moment from "moment";
import {
  getRefralCommissionUserList,
  getOverview,
  getRefralCommissionUserListLevel,
  getRefralCommissionTree,
  convertToRP,
  intersetEarned,
  createAccount,
  transferPoints,
} from "./actions/investActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import LevelUsers from "./LevelUsers";
import Modal from "react-responsive-modal";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Selector from "../Common/selector";
import Input from "../Common/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { signup } from "./actions/signupActions";
import { payload } from "../../utils/dummyRef";
import ReferralTree from "./ReferralTree";

const suSchema = yup.object().shape({
  user_name: yup.string().required("User Name is a required field."),
  first_name: yup.string().required("First Name is a required field."),
  last_name: yup.string().required("Last Name is a required field."),
  email: yup
    .string()
    .required("Email is a required field.")
    .email("Insert a valid email address."),
  phone_number: yup.string().required("Phone Number is a required field."),
  referral_number: yup
    .string()
    .required("Referral Number is a required field."),
  password: yup.string().required("Password is a required field."),
  confirm_password: yup
    .string()
    .required("Confirm Password is a required field."),
});

const RefferalCommission = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    investment_refral_commissions,
    investment_refral_commissions_tree,
    investment_interest_commissions,
  } = useSelector((state) => state?.history);
  const { backend_errors } = useSelector((state) => state.error);
  const { loading } = useSelector((state) => state?.loading);
  const { overview } = useSelector((state) => state?.overview);
  const { user } = useSelector((state) => state?.auth.user);
  console.log(user);
  const [response, setResponse] = useState([]);
  const [referralTree, setReferralTree] = useState(null);
  const [maxLevels, setMaxLevels] = useState(0);
  const [treeNull, setTreeNull] = useState(false);
  const [showConvertModal, setShowConverModal] = useState(false);
  const [showSignupModal, setShowSignupModa] = useState(false);

  const [recipientUsername, setRecipientUsername] = useState("");
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState(null);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const handleClose = () => setShowConverModal(false);
  const handleShow = () => setShowConverModal(true);
  const handleSUMOpen = () => setShowSignupModa(true);
  const handleSUMClose = () => setShowSignupModa(false);
  const handleTransferModalOpen = () => {
    setShowTransferModal(true);
    setError("");
  };

  const handleTransferModalClose = () => {
    setShowTransferModal(false);
    setError("");
  };
  const handleTransferPoints = (e) => {
    e.preventDefault();
    setError("");
    dispatch(
      transferPoints(recipientUsername, amount, setShowTransferModal),
      setError
    );
  };

  const [refCode, setReferralCode] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const {
    register: registerSignup,
    handleSubmit: handleSUSubmit,
    formState: { errors: suErrors },
  } = useForm({
    values: {
      user_name: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      confirm_password: "",
    },
    resolver: yupResolver(suSchema),
  });

  const _createAccount = (data) => {
    console.log(data);
    data.user_name = data.user_name.toLowerCase();
    if (data?.password === data?.confirm_password) {
      setError(null);
      dispatch(signup(data, history, true));
    } else {
      alert("Password and Confirm password should be same.");
    }
    // userAxios.post('http://13.215.175.5:4000/v1/invester/investment/create-newaccount')
    //   .then(response => {
    //     if (response.status === 200 && response.data && response.data.url) {
    //       // Open the redirection URL in a new tab
    //       history.push(response.data.url, '_blank');
    //     } else if (response.status === 400 && response.data && response.data.message) {
    //       // Show error message if user does not have enough points
    //       showToast('error', response.data.message);
    //     } else {
    //       // Handle other cases
    //       showToast('error', 'Failed to create account');
    //     }
    //   })
    //   .catch(error => {
    //     // Error handling
    //     console.error('Error creating account:', error);
    //     showToast('error', 'Failed to create account');
    //   });
  };
  useEffect(() => {
    if (backend_errors) {
      setError(backend_errors);
    }
  }, [backend_errors]);

  useEffect(() => {
    dispatch(getOverview());
  }, []);

  const typeOption = [
    { label: "ROI", value: "Interest Commission" },
    { label: "Referral Bonus", value: "Referral Commission" },
  ];

  const gpTypeOption = [
    { label: "ROI", value: "Interest Commission" },
    { label: "Referral Bonus", value: "Referral Commission" },
    { label: "Group Bonus", value: "Group Bonus" },
  ];

  useEffect(() => {
    dispatch(getRefralCommissionUserList("Approved"));
    dispatch(intersetEarned("Approved"));
    dispatch(getRefralCommissionTree());
    dispatch(getOverview());
    dispatch(getRefralCommissionUserListLevel());
  }, []);

  const schema = yup.object().shape({
    earningType: yup.string().required("Commission type is a required field."),
    amount: yup
      .number()
      .positive("Amount should be a positive value.")
      .moreThan(0, "Amount should be greater than 0.")
      .test(
        "is-valid-amount",
        "Amount is greater than available balance",
        function (value) {
          const earningType = this.parent.earningType;

          if (earningType === "Interest Commission") {
            return value <= overview?.interest_earned;
          }

          if (earningType === "Referral Commission") {
            return value <= overview?.refral_earned;
          }

          return false;
        }
      )
      .required("Amount is a required field."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      amount: "",
      earningType: "",
    },
    resolver: yupResolver(schema),
  });

  const _convert = (data) => {
    setError(null);
    dispatch(convertToRP(data, setShowConverModal));
  };

  function setLevels(data) {
    const topLevelUsers = data["level1"];
    if (!topLevelUsers) {
      setTreeNull(true);
      return [];
    }

    let isNull = false;

    Object.keys(data).map((level, index) => {
      if (data[level] !== null && data[level]?.length !== 0) {
        if (!isNull) {
          setMaxLevels(index + 1);
        }
      } else {
        isNull = true;
      }
    });
  }
  useEffect(() => {
    if (Object.keys(investment_refral_commissions_tree).length > 0) {
      if (
        investment_refral_commissions_tree.level1 === null ||
        investment_refral_commissions_tree.level1?.length === 0
      ) {
        setTreeNull(true);
      } else {
        setLevels(investment_refral_commissions_tree);
        setReferralTree(investment_refral_commissions_tree);
      }
    }
  }, [investment_refral_commissions_tree]);

  useEffect(() => {
    if (
      investment_refral_commissions?.length > 0 ||
      investment_interest_commissions?.length > 0
    ) {
      const tResp = [].concat(
        investment_refral_commissions,
        investment_interest_commissions
      );
      tResp.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setResponse(
        tResp?.map((item) => {
          return {
            ...item,
            user_name: item?.account_name,
            time: moment(item?.createdAt).format("YYY-MM-DD H:MM:SS"),
            type: item?.user_id?.type,
            status: item?.status, // Keep status as a string
          };
        })
      );
    }
  }, [investment_refral_commissions, investment_interest_commissions]);
  
  const columns = [
    {
      name: "user_name",
      label: "Account Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "time",
      label: "Time",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <td
              onClick={() =>
                history.push({
                  pathname: "/user/withdraw-funds-2",
                  state: {
                    details: response[tableMeta.rowIndex], // Use the current row data
                  },
                })
              }
            >
              <button>{value}</button>
            </td>
          );
        },
      },
    },
    {
      name: "commission_received",
      label: "Commission Received",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const status = tableMeta.rowData[4]; // Assuming 'status' is the 5th column
          return (
            <span>
              {status === 'Available' ? (
                <span>&#10006;</span> // Cross icon (✖)
              ) : status === 'Approved' ? (
                <span>&#10004;</span> // Tick icon (✔)
              ) : null}
            </span>
          );
        },
      },
    },
  ];
  
 
  
console.log(response)
  const getLevels = () => {
    let levs = [];
    for (let levels = 1; levels <= maxLevels; levels++) {
      levs.push(levels);
    }
    return (
      <>
        {levs.map((level, index) => (
          <div
            key={index}
            style={{ height: "3rem", borderBottom: "1px solid black" }}
            className="d-flex flex-column justify-content-center"
          >
            <h6 className="">Level {level}</h6>
          </div>
        ))}
      </>
    );
  };

  const getGroupBonus = () => {
    if (Object.keys(overview).includes("group_bonus")) {
      return (
        <div className="mt-3">
          <p className="light-txts text-center mb-1">{t("Group Bonus")}</p>
          <div className="both-shadow">
            <p className="text-center m-0">
              {loading ? (
                <Skeleton />
              ) : overview?.group_bonus ? (
                `USDT ${overview?.group_bonus}`
              ) : (
                0
              )}
            </p>
          </div>
        </div>
      );
    }
  };

  const getTotalValue = () => {
    if (Object.keys(overview).includes("group_bonus")) {
      return (
        overview.refral_earned +
        overview.interest_earned +
        overview.group_bonus +
        overview.total_invest
      );
    } else {
      return (
        overview.refral_earned +
        overview.interest_earned +
        overview.total_invest
      );
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <NavBar />
      <div className="refferal_commission refferal-table-wrapper">
        <Side_bar />
        <div className="content_bar">
          <div className="page-content">
            <h1 className="text-center">{t("Incomes")}</h1>
            <div className="outer-card re-outer">
              <h6 className="mb-3">{t(`${user?.user_name}'s Wallet`)}</h6>
              <div className="inner-card">
                <div className="trans-summary">
                  <div>
                    <div>
                      <p className="light-txt text-center mb-1">
                        {t("Total Deposit")}
                      </p>
                      <div className="sgd both-shadow m-auto">
                        <h5 className="m-0">
                          {t("USDT")}
                          <br />
                          {loading ? (
                            <Skeleton />
                          ) : overview?.total_invest > 0 ? (
                            <h5 className="m-0 text-center">
                              {!user?.isDummy ? overview?.total_invest : ` 0`}
                            </h5>
                          ) : (
                            <h5 className="m-0 text-center">{` 0`}</h5>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="light-txt text-center mb-1">{t("ROI")}</p>
                      <div className="both-shadow">
                        <p className="text-center m-0">
                          {loading ? (
                            <Skeleton />
                          ) : overview?.interest_earned ? (
                            `USDT ${overview?.interest_earned}`
                          ) : (
                            0
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="light-txt text-center mb-1">
                        {t("Referral Earned")}
                      </p>
                      <div className="both-shadow">
                        <p className="text-center mb-0">
                          {loading ? (
                            <Skeleton />
                          ) : overview?.refral_earned ? (
                            `USDT ${overview?.refral_earned}`
                          ) : (
                            0
                          )}
                        </p>
                      </div>
                    </div>
                    {getGroupBonus()}
                    <div className="mt-3">
                      <div className="ref-id">
                        <p className="light-txt text-center mb-1">
                          {t("My Referral ID")}
                        </p>
                        <div className="both-shadow">
                          <p className="text-center mb-0">
                            {user?.refral_code}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className="light-txt text-center mb-1">
                        {t("Registration Point")}
                      </p>
                      <div className="sgd both-shadow m-auto">
                        <h5 className="m-0">
                          {t("USDT")}
                          <br />
                          {loading ? (
                            <Skeleton />
                          ) : overview?.registration_point > 0 ? (
                            <h5 className="m-0 text-center">
                              {!user?.isDummy
                                ? overview?.registration_point
                                : ` 0`}
                            </h5>
                          ) : (
                            <h5 className="m-0 text-center">{` 0`}</h5>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="mt-3 text-center">
                      <p className="light-txt text-center mb-1">
                        {t("Convert Bonus")}
                      </p>
                      <div>
                        <div
                          className="both-shadow w-50 m-auto p-0"
                          style={{
                            backgroundColor: "#12491d",
                            color: "white",
                            border: "2px solid #12491d",
                            boxShadow: "none",
                            outline: "2px solid #12491d",
                            cursor: "pointer",
                          }}
                          onClick={handleShow}
                        >
                          <p className="m-0">{t("Convert")}</p>
                        </div>

                        <Modal
                          open={showConvertModal}
                          closeOnOverlayClick
                          closeOnEsc
                          closeIcon
                          center
                          onClose={handleClose}
                        >
                          <h3 className="text-center mb-3">
                            {t("Convert Bonus")}
                          </h3>
                          <div className="row justify-content-center d-flex mb-3 gap-5 align-items-center">
                            <div className="w-25">
                              <p className="light-txts text-center mb-1">
                                {t("Referral Earned")}
                              </p>
                              <div className="both-shadow m-auto">
                                <p className="text-center mb-0">
                                  {loading ? (
                                    <Skeleton />
                                  ) : overview?.refral_earned ? (
                                    `USDT ${overview?.refral_earned}`
                                  ) : (
                                    0
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="w-25">
                              <p className="light-txts text-center mb-1">
                                {t("ROI")}
                              </p>
                              <div className="both-shadow">
                                <p className="text-center m-0">
                                  {loading ? (
                                    <Skeleton />
                                  ) : overview?.interest_earned ? (
                                    `USDT ${overview?.interest_earned}`
                                  ) : (
                                    0
                                  )}
                                </p>
                              </div>
                            </div>
                            {getGroupBonus()}
                          </div>
                          <form
                            onSubmit={handleSubmit((data) => _convert(data))}
                          >
                            <div className="inner-card">
                              <div className="mt-2 mb-2">
                                <Selector
                                  classNames={"gap-4"}
                                  label="Type:"
                                  options={
                                    overview?.group_bonus
                                      ? gpTypeOption
                                      : typeOption
                                  }
                                  errors={errors?.network?.message}
                                  defaultOption={"Select Bonus to convert"}
                                  register={register}
                                  name="earningType"
                                />
                              </div>
                              <div className="mt-2 mb-2">
                                <Input
                                  label="Amount:"
                                  errors={errors?.amount?.message}
                                  type="text"
                                  name="amount"
                                  className={true}
                                  register={register}
                                />
                              </div>
                              {error && (
                                <span
                                  className="fields"
                                  style={{ color: "red", marginTop: "10px" }}
                                >
                                  {error && error?.message}
                                </span>
                              )}

                              <div className="d-flex justify-content-around my-4">
                                <button type="submit">{t("Convert")}</button>
                              </div>
                            </div>
                          </form>
                        </Modal>
                      </div>
                    </div>
                    <div className="w-100 text-center mt-3 ">
                      <button
                        className="btn pt-0 pb-0 w-50"
                        style={{
                          backgroundColor: "#12491d",
                          color: "white",
                          border: "2px solid #12491d",
                          boxShadow: "none",
                          outline: "2px solid #12491d",
                          cursor: "pointer",
                          padding: "10px 10px",
                        }}
                        onClick={handleTransferModalOpen}
                      >
                        Transfer Points
                      </button>
                      <Modal
                        open={showTransferModal}
                        onClose={handleTransferModalClose}
                        center
                      >
                        {/* Transfer points form */}
                        <form onSubmit={handleTransferPoints}>
                          {/* Input fields for recipient username and amount */}
                          <input
                            type="text"
                            value={recipientUsername}
                            onChange={(e) =>
                              setRecipientUsername(e.target.value)
                            }
                            placeholder="Recipient Username"
                          />
                          <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Amount"
                          />

                          {/* Submit button */}
                          <button type="submit">Transfer</button>
                        </form>
                      </Modal>
                    </div>
                    {overview?.registration_point > 2999 && (
                      <div className="w-100 text-center mt-3 ">
                        <button
                          className="btn pt-0 pb-0 w-50"
                          style={{
                            backgroundColor: "#12491d",
                            color: "white",
                            border: "2px solid #12491d",
                            boxShadow: "none",
                            outline: "2px solid #12491d",
                            cursor: "pointer",
                            padding: "10px 10px",
                          }}
                          onClick={handleSUMOpen}
                        >
                          Create Account
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <Modal
                  open={showSignupModal}
                  closeOnOverlayClick
                  closeOnEsc
                  closeIcon
                  center
                  onClose={handleSUMClose}
                >
                  <h3 className="text-center">{t("Create Account")}</h3>
                  <form
                    onSubmit={handleSUSubmit((data) => _createAccount(data))}
                  >
                    <div className="user-signup mt-3">
                      <div className="signup-card">
                        <div className="signup-section w-100 m-0 p-0 shadow-none">
                          <h2>{t("Sign Up")}</h2>
                          {error?.message && (
                            <span className="fields" style={{ color: "red" }}>
                              {error?.message && error?.message}
                            </span>
                          )}
                          <Input
                            label={t("User Name")}
                            errors={suErrors?.user_name?.message}
                            type="user_name"
                            name="user_name"
                            register={registerSignup}
                          />
                          <Input
                            label={t("First Name")}
                            errors={suErrors?.first_name?.message}
                            type="first_name"
                            name="first_name"
                            register={registerSignup}
                          />
                          <Input
                            label={t("Last Name")}
                            errors={suErrors?.last_name?.message}
                            type="last_name"
                            name="last_name"
                            register={registerSignup}
                          />
                          <Input
                            label={t("Email")}
                            errors={suErrors?.email?.message}
                            type="email"
                            name="email"
                            register={registerSignup}
                          />
                          <Input
                            label={t("Phone")}
                            errors={suErrors?.phone_number?.message}
                            type="phone_number"
                            name="phone_number"
                            register={registerSignup}
                          />
                          <Input
                            errors={suErrors?.referral_number?.message}
                            value={refCode}
                            onChange={(e) => setReferralCode(e.target.value)}
                            type="referral_number"
                            name="referral_number"
                            register={registerSignup}
                            label={t("Referral Number")}
                          />
                          <div
                            className="password-field"
                            style={{ position: "relative" }}
                          >
                            <Input
                              errors={suErrors?.password?.message}
                              type={showPassword ? "text" : "password"}
                              name="password"
                              register={registerSignup}
                              label={t("Password")}
                            />
                            <button
                              type="button"
                              onClick={togglePasswordVisibility}
                              className="toggle-password-visibility"
                            >
                              <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                              />
                            </button>
                          </div>
                          <div
                            className="password-field"
                            style={{ position: "relative" }}
                          >
                            <Input
                              errors={suErrors?.confirm_password?.message}
                              type={showPassword ? "text" : "password"}
                              name="confirm_password"
                              register={registerSignup}
                              label={t("Confirm Password")}
                            />
                            <button
                              type="button"
                              onClick={togglePasswordVisibility}
                              className="toggle-password-visibility"
                            >
                              <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                              />
                            </button>
                          </div>
                          <div className="signup-btn">
                            {!loading ? (
                              <button type="submit">
                                {t("Create Account")}
                              </button>
                            ) : (
                              <button type="button">
                                <div className="loader"></div>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </Modal>

                <div className="w-100 mt-3">
                  <p className="light-txt text-center mb-1">
                    {t("Total Value")}
                  </p>
                  <div className="both-shadow">
                    <p className="text-center mb-0">
                      {!loading ? (
                        <>
                          {user?.isDummy
                            ? `USDT ${
                                overview?.interest_earned +
                                overview?.refral_earned
                              }`
                            : `USDT ${getTotalValue()}`}
                        </>
                      ) : (
                        <Skeleton />
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="trans-content interest-earned-btn ref-table">
              <h1>{t("Refferal Commission Tree")}</h1>
              <div className="p-2" style={{ borderRadius: "23px" }}>
                <ReferralTree
                  user={user?._id}
                  data={investment_refral_commissions_tree}
                  level={0}
                />
              </div>
              {/* {loading ? (
                <Skeleton />
              ) : (
                <>
                  {treeNull ? (
                    <div className="text-center">
                      <h6>No users referred Yet</h6>
                    </div>
                  ) : (
                    <div className="w-100 d-flex trans-content interest-earned-btn ref-table my-3">
                      <div
                        className="w-25 text-center p-3"
                        style={{
                          borderRight: "1px solid black",
                        }}
                      >
                        <h4>Levels</h4>
                        {getLevels()}
                      </div>
                      <div className="w-75 text-center p-3 ">
                        <h4>Tree</h4>
                        {referralTree !== null && (
                          <LevelUsers data={referralTree} level={1} />
                        )}
                      </div>
                    </div>
                  )}
                </>
              )} */}
            </div>
            <div className="trans-content interest-earned-btn">
              <h1>{t("Commission History")}</h1>
              <MUIDataTable
                title={"Commission History"}
                data={response}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefferalCommission;
