import { adminAxios } from "../../config/config";

const setAdminData = authData => {
    if (authData) {
        adminAxios.defaults.headers.common['token'] = `${authData.token}`;
    } else {
        delete adminAxios.defaults.headers.common['token'];
    }
};

export default setAdminData;