import { CLEAR_ERRORS, GET_ERRORS } from "../../../utils/types";

const initialState = {
  backend_errors: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      console.log("1",action.payload)
      return {
        ...state,
        backend_errors: action.payload
      }
    case CLEAR_ERRORS:
      return {
        backend_errors : null
      }
    default:
      return state;
  }
}