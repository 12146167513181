import { CLEAR_ERRORS, GET_ERRORS } from '../../../utils/types';

//Get Errors
export const getError = error => dispatch => {

  if (error.response) {
    if (error.response.status === 401) {
      if (localStorage.user_auth_data) {
        // dispatch(logout())
      }
      if (localStorage.admin_auth_data) {
        // dispatch(adminLogout())
      }
    }
    if (error.response.status !== 200) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response && error.response.data
      });
    }
  }
};

export const clearErrors = () => dispatch => {
  dispatch({
    type: CLEAR_ERRORS
  });
};