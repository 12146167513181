import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./UserPanel.scss";
import { useTranslation } from "react-i18next";

const Side_bar = () => {
  const { t, i18n } = useTranslation();

  const setActive = (link) => {
    const currentLink = window.location.pathname.split("/")[2];

    if (link === currentLink) {
      return "active-tab";
    }
  };

  return (
    <>
      <div className="side_bar d-none d-md-block">
        <Link
          className={setActive("investment-overview")}
          to="/user/investment-overview"
        >
          {t("Investment Overview")}
        </Link>
        <Link className={setActive("invest")} to="/user/invest">
          {t("Invest")}
        </Link>
        <Link className={setActive("incomes")} to="/user/incomes">
          {t("Incomes")}
        </Link>
        <Link
          className={setActive("withdraw-funds-2")}
          to="/user/withdraw-funds-2"
        >
          {t("Withdrawal")}
        </Link>
        <Link to={{ pathname: "https://maconhaholding.asia/" }} target="_blank">
          {t("Home")}{" "}
        </Link>
      </div>
    </>
  );
};

export default Side_bar;
