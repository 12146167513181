import { userAxios } from "../../../config/config";
import setUserData from "../../../utils/auth/setUserData";
import { SET_CURRENT_USER } from "../../../utils/types";

export const login = (userData, history) => (dispatch) => {
  userAxios
    .post(`/invester/user/login`, userData)
    .then((res) => {
      // Save to localStorage
      let user_auth_data = {};
      user_auth_data.token = res.headers["token"];
      // Set token to localStorage
      localStorage.setItem("user_data", JSON.stringify(res.data.payload));
      setUserData(user_auth_data);
      // Set current user
      dispatch(setCurrentUser(res.data.payload));
      history.push("/user/investment-overview");
      window.location.reload();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const resetPassword = (userData, history) => (dispatch) => {
  userAxios.post(`/common/auth/reset-password`, userData).then((res) => {
    history.push("/user/login");
  });
};

export const setCurrentUser = (userData) => {
  return {
    type: SET_CURRENT_USER,
    payload: userData,
  };
};

export const logout = (history) => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("user_data");
  // Remove auth header for future requests
  setUserData(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
