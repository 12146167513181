import React from "react";
import ReferralLevel from "./ReferralLevel";

const ReferralTree = ({ user, data, level }) => {
  const children = data?.[`level${level + 1}`]?.filter(
    (child, _) => child?.parent === user
  );

  console.log(level);
  console.log(user);

  if (children?.length === 0) {
    return <p>No Referred Users Found</p>;
  } else {
    return (
      <div className="d-flex flex-column gap-1">
        {children?.map((child, index) => (
          <ReferralLevel
            user={child}
            index={index}
            level={level + 1}
            data={data}
          />
        ))}
      </div>
    );
  }
};

export default ReferralTree;
