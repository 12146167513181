import React, { useEffect, useState } from "react";
import "./Transactions.scss";
import AdminNavBar from "../AdminNavBar";
import {
  checkInvestment,
  getInterestCommissionList,
  listOfInvestments,
  listOfWithdraw,
} from "../actions/investorsActions";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";

import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import { showToast } from "../../../utils/toast";

const Transactions = () => {
  const dispatch = useDispatch();
  const { investments, withdraw, investment_interest_commissions } =
    useSelector((state) => state?.investments);
  const [transactionResponse, setTransactionReponse] = useState([]);
  const [commissionsResponse, setCommissionsReponse] = useState([]);
  const [open, setOpen] = useState(false);
  const [popup, setPopup] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user_name } = useSelector((state) => state?.adminAuth?.admin?.user);

  useEffect(() => {
    dispatch(listOfInvestments("Pending"));
    dispatch(listOfWithdraw("Pending"));
    dispatch(getInterestCommissionList("Available"));
  }, []);

  const option = [
    { label: "BSC", value: "0x17d091ade593c7404f558ffB21102Fa96e357164" },
    { label: "TRC", value: "TW1JNHYVBpw8hhSWVkasTMZfUoegTthD3T" },
    { label: "ERC", value: "0x238B40131C9760CDbCa61505C65429Ef62D7eBA3" },
  ];

  useEffect(() => {
    if (
      investments?.length > 0 ||
      withdraw?.length > 0 ||
      investment_interest_commissions?.length > 0
    ) {
      const tResp = [].concat(investments, withdraw);
      console.log(tResp);
      setTransactionReponse(
        tResp.map((item) => {
          const data = option.filter((obj) => obj.value === item?.network);
          return {
            ...item,
            name: item?.user_id?.user_name + " ",
            tax_hash: (
              <td
                style={{ color: "#12491d" }}
                onClick={() => window.open(`${data[0]?.url + item?.tax_hash}`)}
              >
                {item?.tax_hash?.slice(0, 5)}{" "}
                {item?.tax_hash?.slice(0, 5) > 0 && "..."}
              </td>
            ),
            network: (
              <td>{data?.length > 0 ? data[0]?.label : item?.network}</td>
            ),
            details: (
              <td>
                <a
                  className="btn btn-success"
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    handleDetails(item);
                  }}
                >
                  Read
                </a>
              </td>
            ),
            status: (
              <td>
                <button
                  style={{ marginRight: "10px" }}
                  onClick={() => updateTransactionStatus(item, "Approved")}
                >
                  Approve
                </button>
                <button
                  onClick={() => updateTransactionStatus(item, "Rejected")}
                >
                  Reject
                </button>
              </td>
            ),
            createdAt:
              new Date(item.createdAt).toLocaleDateString() +
              new Date(item.createdAt).toLocaleTimeString(),
          };
        })
      );

      const cResp = [].concat(investment_interest_commissions);
      setCommissionsReponse(
        cResp.map((item) => {
          return {
            ...item,
            name: item?.user_id?.user_name + " ",
            type: item?.user_id?.type,
            network: item?.network,
            details: (
              <td>
                <a
                  className="btn btn-success"
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    handleDetails(item);
                  }}
                >
                  Read
                </a>
              </td>
            ),
            status: (
              <td>
                <button
                  style={{ marginRight: "10px" }}
                  onClick={() => updateCommissionsStatus(item, "Approved")}
                >
                  Approve
                </button>
                <button
                  onClick={() => updateCommissionsStatus(item, "Rejected")}
                >
                  Reject
                </button>
              </td>
            ),
            createdAt:
              new Date(item.createdAt).toLocaleDateString() +
              new Date(item.createdAt).toLocaleTimeString(),
          };
        })
      );
    }
  }, [investments, withdraw, investment_interest_commissions]);

  const updateTransactionStatus = (data, status) => {
    if (user_name !== "admin2") {
      setLoading(true);
      dispatch(checkInvestment(data, status, setLoading));
    } else {
      showToast("error", `Action not allowed`);
    }
    // showToast("success", `Transaction ${status} Successfully`);
  };
  const updateCommissionsStatus = (data, status) => {
    if (user_name !== "admin2") {
      setLoading(true);
      dispatch(checkInvestment(data, status, setLoading));
    } else {
      showToast("error", `Action not allowed`);
    }

    // showToast("success", `Commission ${status} Successfully`);
  };

  const handleDetails = (res) => {
    console.log(res);
    const data = option.filter((obj) => obj.label === res?.network);
    setOpen(true);

    let popup = {
      ...res,
      network: data?.length > 0 ? data[0]?.label : res?.network,
      url: `${data[0]?.url + res?.tax_hash}`,
      neturl: `${data[0]?.neturl}`,
    };
    setPopup(popup);
    console.log(popup);
  };

  const transactionColumns = [
    {
      name: "name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "tax_hash",
      label: "Trx Hash",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "network",
      label: "Netwok",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "details",
      label: "Details",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Action",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const commissionsColumns = [
    {
      name: "name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "percentage",
      label: "Percentage",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "reason",
      label: "Reason",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "details",
      label: "Details",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Action",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  console.log(transactionResponse);
  console.log(commissionsResponse);

  const { t, i18n } = useTranslation();
  console.log(t);
  return (
    <>
      {loading && (
        <div id="loader" className="loader-overlay">
          <div className="loader-box">
            <div id="preLoader" />
          </div>
        </div>
      )}
      <AdminNavBar />
      <div className="transactions-section">
        <div className="transactions-content">
          <h2>{t("Transactions")}</h2>
          <MUIDataTable
            title={"Transactions"}
            data={transactionResponse}
            columns={transactionColumns}
            // options={options}
          />
        </div>
        <div className="transactions-content mt-5">
          <h2>{t("Commissions")}</h2>
          <MUIDataTable
            title={"Commissions"}
            data={commissionsResponse}
            columns={commissionsColumns}
            // options={options}
          />
        </div>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
            setPopup(null);
          }}
          center
        >
          <>
            <div className="transaction-hx">
              <div className="trans-contents">
                <h1 className="mb-3">{popup?.user_id?.type}</h1>
                {popup?.user_id?.type === "Withdrawal" && (
                  <div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("User Name")}:</strong>
                      <p className="mb-0">{popup?.user_id?.user_name}</p>
                    </div>

                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("Amount")}</strong>
                      <p className="mb-0">{popup?.amount}</p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">
                        {t("Wallet Address")}
                      </strong>
                      <p className="mb-0">{popup?.wallet_address}</p>
                    </div>
                  </div>
                )}
                {popup?.user_id?.type === "Deposit" && (
                  <div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("User Name")}:</strong>
                      <p className="mb-0">{popup?.user_id?.user_name}</p>
                    </div>

                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("Trx Hash")}:</strong>
                      <p
                        className="mb-0"
                        style={{ color: "#12491d" }}
                        onClick={() => window.open(popup?.url)}
                      >
                        {popup?.tax_hash?.slice(0, 15)}...
                      </p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">Network</strong>
                      <p className="mb-0">{popup?.network}</p>
                    </div>
                    <div className="d-flex flex-wrap align-items-center mt-5">
                      <strong className="mr-3 mb-0">{t("Image Proof")}:</strong>
                      <p>
                        <img
                          alt="not fount"
                          width={"250px"}
                          src={popup?.image}
                        />
                      </p>
                    </div>
                  </div>
                )}
                {popup?.user_id?.type === "Registration Point" && (
                  <div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("User Name")}:</strong>
                      <p className="mb-0">{popup?.user_id?.user_name}</p>
                    </div>

                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("Amount")}:</strong>
                      <p className="mb-0">{popup?.amount}</p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("Reason")}:</strong>
                      <p className="mb-0">{popup?.reason}</p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("Percentage")}:</strong>
                      <p className="mb-0">{popup?.percentage}</p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">
                        {t("Country Name")}:
                      </strong>
                      <p className="mb-0">{popup?.country}</p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">
                        {t("Account Holder Name")}:
                      </strong>
                      <p className="mb-0">{popup?.account_name}</p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">
                        {t("Bank Account Number")}:
                      </strong>
                      <p className="mb-0">{popup?.account_no}</p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("Swift Code")}:</strong>
                      <p className="mb-0">{popup?.swift_code}</p>
                    </div>
                  </div>
                )}
                {popup?.user_id?.type === "Interest Commission" && (
                  <div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("Username")}:</strong>
                      <p className="mb-0">{popup?.user_id?.user_name}</p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("Amount")}:</strong>
                      <p className="mb-0">{popup?.amount}</p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("Reason")}:</strong>
                      <p className="mb-0">{popup?.reason}</p>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <strong className="mr-3 mb-0">{t("Percentage")}:</strong>
                      <p className="mb-0">{popup?.percentage}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </Modal>
      </div>
    </>
  );
};

export default Transactions;
