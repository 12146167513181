import React, { useEffect, useState } from "react";
import Side_bar from "../Side_bar";
import "./MyProfile.scss";
import Input from "../../Common/Input";
import { useForm } from "react-hook-form";
import NavBar from "../../NavBar";
import { useDispatch, useSelector } from "react-redux";
import { password_update, profile_update } from "../actions/profileActions";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { showToast } from "../../../utils/toast";

const MyProfile = () => {
  const { user } = useSelector((state) => state?.auth.user);
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Email is a required field.")
      .email("Insert a valid email address."),
    phone_number: yup.string().required("phone number is required field"),
  });
  const schemaUP = yup.object().shape({
    newPassword: yup.string().required("New Password field is required."),
    confirmNewPassword: yup.string().required("Please confirm your password"),
  });
  const QRUrl = `https://app.maconhaholding.asia/user/signup?referral=${user.refral_code}`;
  const dispatch = useDispatch();
  const history = useHistory();
  const { backend_errors } = useSelector((state) => state.error);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: user,
    resolver: yupResolver(schema),
  });

  const {
    register: registerUP,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsUP },
  } = useForm({
    resolver: yupResolver(schemaUP),
  });

  useEffect(() => {
    if (backend_errors) {
      showToast("error", backend_errors?.message);
    }
  }, [backend_errors]);

  const _update = (data) => {
    if (user.email === data.email && user.phone_number === data.phone_number) {
      showToast("info", "No changes made.");
    } else {
      dispatch(profile_update(data, history));
    }
  };

  const _updatePassword = (data) => {
    dispatch(password_update(data, history));
  };

  const handleShareClick = () => {
    navigator.clipboard
      .writeText(QRUrl)
      .then(() => {
        showToast("error", "Profile link copied to clipboard!");
      })
      .catch((error) => {
        console.error("error", "Failed to copy text: ", error);
      });
  };

  const { t, i18n } = useTranslation();
  return (
    <>
      <NavBar />
      <div className="user-section">
        <Side_bar />
        <div className="user-content">
          <div className="profile-content">
            <div
              className="d-flex justify-content-around flex-lg-row flex-column"
              style={{ gap: "24px" }}
            >
              <div className="profile-card flex-grow-1">
                <div className="profile-section mb-0 h-100">
                  <h2 className="mb-4">{t("My Profile")}</h2>
                  <div className="field-area">
                    <div className="fields">
                      <label>{t("First Name")}</label>
                      <div>{user.first_name}</div>
                    </div>
                    <div className="fields">
                      <label>{t("Last Name")}</label>
                      <div>{user.last_name}</div>
                    </div>
                  </div>

                  <div className="field-area">
                    <div className="fields">
                      <label>{t("Joining Date")}</label>
                      <div>{new Date(user.createdAt).toLocaleDateString()}</div>
                    </div>
                  </div>
                  <div className="field-area">
                    <div className="fields">
                      <label>{t("Email")}</label>
                      <div>{user.email}</div>
                    </div>
                  </div>

                  <div className="field-area">
                    <div className="fields">
                      <label>{t("Phone")} #</label>
                      <div>{user.phone_number}</div>
                    </div>
                  </div>

                  <div className="field-area">
                    <div className="fields">
                      <label>{t("My Referral")} #</label>
                      <div>{user.refral_code}</div>
                    </div>
                  </div>
                  <div className="field-area">
                    <div className="fields">
                      <label>{t("Referral QR Code")}</label>
                      <QRCode value={QRUrl} />
                    </div>
                  </div>
                  <div className="field-area">
                    <div className="fields">
                      <label>{t("Referral Link : ")}</label>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleShareClick()}
                      >
                        Copy Link
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex flex-column flex-grow-1"
                style={{ gap: "16px" }}
              >
                <div className="profile-card h-50">
                  <div className="profile-section m-0 h-100">
                    <h3 className="mb-4 text-center">{t("Update profile")}</h3>
                    <form onSubmit={handleSubmit((data) => _update(data))}>
                      <div className="field-area d-flex flex-column">
                        <div className="fields mb-0">
                          <Input
                            label="Update Email"
                            errors={errors?.email?.message}
                            type="email"
                            name="email"
                            register={register}
                          />
                        </div>
                        <div className="fields mb-0">
                          <Input
                            label="Update Phone#"
                            errors={errors?.phone_number?.message}
                            type="phone_number"
                            name="phone_number"
                            register={register}
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-3 justify-content-center">
                        <button type="submit">{t("Update Profile")}</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="profile-card h-50">
                  <div className="profile-section m-0 h-100">
                    <h3 className="mb-4 text-center">{t("Update Password")}</h3>
                    <form onSubmit={handleSubmitPassword(_updatePassword)}>
                      <div className="field-area d-flex flex-column">
                        <div className="fields mb-0">
                          <Input
                            label="New Password"
                            errors={errorsUP?.newPassword?.message}
                            type="text"
                            name="newPassword"
                            register={registerUP}
                          />
                        </div>
                        <div className="fields mb-0">
                          <Input
                            label="Confirm Password"
                            errors={errorsUP?.confirmNewPassword?.message}
                            type="text"
                            name="confirmNewPassword"
                            register={registerUP}
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-3 justify-content-center">
                        <button type="submit">{t("Update Password")}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
