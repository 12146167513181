import { adminAxios } from "../../../config/config";
import { showToast } from "../../../utils/toast";
import {
  GET_INVESTORS,
  GET_INVESTMENTS,
  GET_WITHDRAW,
  GET_REFRAL_COMMISSION,
  GET_INTEREST_COMMISSION,
  REFRAL_TREE,
  GET_PAST_INVESTMENTS,
  GET_PAST_WITHDRAW,
  GET_PAST_REFRAL_COMMISSIONS,
  GET_PAST_INTEREST_COMMSIONS,
  REFRAL_HISTORY,
  INTERST_HISTORY,
  GROUP_BONUS_HISTORY,
} from "../../../utils/types";

const currentAdmin = JSON.parse(localStorage.getItem("admin_data"));
const token = currentAdmin?.token;
const headers = {
  'token': token
};
console.log("🚀 ~ currentAdmin:", currentAdmin, currentAdmin?.user?.user_name )
export const listOfInvestors = () => (dispatch) => {
  if (currentAdmin?.user?.user_name !== 'admin') {
    // If the username is 'admin2', use the new API endpoint
    adminAxios.post(`/admin/investment/get-admin2-refralls`, { user_id: currentAdmin?.user?._id }, { headers: headers })
      .then((res) => {
        dispatch({
          type: GET_INVESTORS,
          payload: res?.data?.payload?.investors,
        });
      });
  } else {
    // If the username is not 'admin2', use the current implementation
    adminAxios.get(`/admin/investment/get-investers`, { headers: headers })
      .then((res) => {
        dispatch({
          type: GET_INVESTORS,
          payload: res?.data?.payload?.investors,
        });
      });
  }
};

export const userRefralTree = (id) => (dispatch) => {
  adminAxios
    .post(`/admin/investment/get-refralls`, { user_id: id })
    .then((res) => {
      dispatch({
        type: REFRAL_TREE,
        payload: res?.data?.payload?.referral_users,
      });
    });
};

export const userRCHistory = (id) => (dispatch) => {
  adminAxios
    .post(`/admin/investment/get-Refrallcommission-history`, { user_id: id })
    .then((res) => {
      console.log(res?.data?.payload);
      dispatch({
        type: REFRAL_HISTORY,
        payload: res?.data?.payload,
      });
    });
};
export const userICHistory = (id) => (dispatch) => {
  adminAxios
    .post(`/admin/investment/get-Interestcommission-history`, { user_id: id })
    .then((res) => {
      console.log(res?.data?.payload);
      dispatch({
        type: INTERST_HISTORY,
        payload: res?.data?.payload,
      });
    });
};
export const userGPHistory = (id) => (dispatch) => {
  adminAxios
    .post(`/admin/investment/get-groupBonus-history`, { user_id: id })
    .then((res) => {
      console.log(res?.data?.payload);
      dispatch({
        type: GROUP_BONUS_HISTORY,
        payload: res?.data?.payload,
      });
    });
};

export const listOfInvestments = (status) => (dispatch) => {
  adminAxios.get(`/admin/investment/?status=${status}`).then((res) => {
    // Save to localStorage
    dispatch({
      type: GET_INVESTMENTS,
      payload: res?.data?.payload?.investments,
    });
  });
};

export const getAllInvestments = () => (dispatch) => {
  adminAxios.get(`/admin/investment/`).then((res) => {
    let raInvestments = [];
    res?.data?.payload?.investments.map((investment) => {
      if (
        investment.status === "Approved" ||
        investment.status === "Rejected"
      ) {
        raInvestments.push(investment);
      }
    });
    // save to localStorage
    dispatch({
      type: GET_PAST_INVESTMENTS,
      payload: raInvestments,
    });
  });
};

export const listOfWithdraw = (status) => (dispatch) => {
  adminAxios
    .get(`/admin/investment/get-withdrawl-requests?status=${status}`)
    .then((res) => {
      // Save to localStorage
      dispatch({
        type: GET_WITHDRAW,
        payload: res?.data?.payload?.withdrawl_requests,
      });
    });
};

export const getAllWithdraw = () => (dispatch) => {
  adminAxios.get(`/admin/investment/get-withdrawl-requests`).then((res) => {
    let raWithdraws = [];
    res?.data?.payload?.withdrawl_requests?.map((withdraw) => {
      if (withdraw.status === "Approved" || withdraw.status === "Rejected") {
        raWithdraws.push(withdraw);
      }
    });
    // save to localStorage
    dispatch({
      type: GET_PAST_WITHDRAW,
      payload: raWithdraws,
    });
  });
};

export const setUserDummy = (status, id, admin) => {
  if (admin !== "admin2") {
    const obj = {
      status,
      id,
    };
    adminAxios
      .post("/admin/investment/set-user-dummy", obj)
      .then((res) => {
        console.log("status Updated");
        showToast("success", `User status updated`);
        window.location.reload();
      })
      .catch((err) => {
        console.log("something went wrong", err);
      });
  } else {
    showToast("error", "Action not allowed");
  }
};

export const checkInvestment = (data, status, setLoading) => (dispatch) => {
  const obj = {
    id: data?._id,
    status: status,
    user_id: data?.user_id?._id,
  };
  switch (data?.user_id?.type) {
    case "Deposit":
      adminAxios.post(`/admin/investment/update-status`, obj).then((res) => {
        if (res?.status === 200) {
          showToast("success", `Deposit Transaction ${status} Successfully`);
          setLoading(false);
          window.location.reload();
        } else {
          showToast("error", `Status Update Failed Successfully`);
          setLoading(false);
        }
      });
      break;
    case "Withdrawal":
      adminAxios
        .post(`/admin/investment/update-withdrawl-request-status`, obj)
        .then((res) => {
          if (res?.status === 200) {
            showToast(
              "success",
              `Withdrawl Transaction ${status} Successfully`
            );
            setLoading(false);

            window.location.reload();
          } else {
            showToast("error", `Status Update Failed Successfully`);
            setLoading(false);
          }
        });
      break;
    case "Referral Commission":
      adminAxios
        .post(`/admin/investment/update-refral-commission-status`, obj)
        .then((res) => {
          if (res?.status === 200) {
            showToast("success", `Referral Commission ${status} Successfully`);
            setLoading(false);

            window.location.reload();
          } else {
            showToast("error", `Status Update Failed Successfully`);
            setLoading(false);
          }
        });
      break;
    case "Interest Commission":
      adminAxios
        .post(`/admin/investment/update-interest-commission-status`, obj)
        .then((res) => {
          if (res?.status === 200) {
            showToast("success", `Interest Commission ${status} Successfully`);
            setLoading(false);

            window.location.reload();
          } else {
            showToast("error", `Status Update Failed Successfully`);
            setLoading(false);
          }
        });
      break;

    default:
      break;
  }
};

export const getRefralCommissionList = (status) => (dispatch) => {
  adminAxios
    .get(`/admin/investment/get-refral-commission-list?status=${status}`)
    .then((res) => {
      // Save to localStorage
      dispatch({
        type: GET_REFRAL_COMMISSION,
        payload: res?.data?.payload?.investment_refral_commissions,
      });
    });
};

export const getAllRefralCommissionList = () => (dispatch) => {
  adminAxios.get(`/admin/investment/get-refral-commission-list`).then((res) => {
    let raRefralCommissions = [];
    res?.data?.payload?.investment_refral_commissions?.map((refComs) => {
      if (refComs.status === "Approved" || refComs.status === "Rejected") {
        raRefralCommissions.push(refComs);
      }
    });
    // save to localStorage
    dispatch({
      type: GET_PAST_REFRAL_COMMISSIONS,
      payload: raRefralCommissions,
    });
  });
};

export const getInterestCommissionList = (status) => (dispatch) => {
  adminAxios
    .get(`/admin/investment/get-interest-commission-list?status=${status}`)
    .then((res) => {
      // Save to localStorage
      dispatch({
        type: GET_INTEREST_COMMISSION,
        payload: res?.data?.payload?.investment_interest_commissions,
      });
    });
};

export const getAllInterestCommissionList = () => (dispatch) => {
  adminAxios
    .get(`/admin/investment/get-interest-commission-list`)
    .then((res) => {
      let raInterestCommissions = [];
      res?.data?.payload?.investment_interest_commissions?.map((intComs) => {
        if (intComs.status === "Approved" || intComs.status === "Rejected") {
          raInterestCommissions.push(intComs);
        }
      });
      // save to localStorage
      dispatch({
        type: GET_PAST_INTEREST_COMMSIONS,
        payload: raInterestCommissions,
      });
    });
};
