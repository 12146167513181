import React, { useEffect, useState } from "react";
import Side_bar from "../Side_bar";
import "./Invest.scss";
import NavBar from "../../NavBar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InvestWithRP from "./InvestWithRP";
import InvestWithWallet from "./InvestWithWallet";

const Invest = () => {
  const { backend_errors } = useSelector((state) => state.error);
  const [error, setError] = useState(null);
  const [investType, setInvestType] = useState("wallet");

  useEffect(() => {
    if (backend_errors) {
      setError(backend_errors);
    }
  }, [backend_errors]);

  const changeInvestType = () => {
    setError(null);
    setInvestType("wallet");
  };

  const { t, i18n } = useTranslation();

  return (
    <>
      <NavBar />
      <div className="user-section">
        <Side_bar />
        <div className="user-content">
          <div className="invest-section">
            <h1>{t("Invest")}</h1>
            {error?.message && (
              <div style={{ margin: "1rem auto", width: "50%" }}>
                <span className="fields" style={{ color: "red" }}>
                  {error?.message && error?.message}
                </span>
              </div>
            )}
            <div className="main-invest" style={{ paddingTop: "1rem" }}>
              <div className="d-flex justify-content-center gap-3 mb-3">
                <button
                  className={"outline-button"}
                  onClick={() => changeInvestType()}
                >
                  Using Wallet
                </button>
                <button
                  className={"outline-button"}
                  onClick={() => setInvestType("RP")}
                >
                  Using Registration Point
                </button>
              </div>
              <div className="invest-card">
                {investType === "RP" ? (
                  <InvestWithRP setError={setError} />
                ) : (
                  <InvestWithWallet setError={setError} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Invest;
