import store from '../store';
import { getError, clearErrors } from '../components/Common/actions/errorActions';
import { START_LOADING, STOP_LOADING } from './types';
import { userAxios, publicAxios, adminAxios } from '../config/config';

const { dispatch } = store;

//Add a request interceptor for user
userAxios.interceptors.request.use(config => {

    let userAuthData = localStorage.user_token !== "undefined" && JSON.parse(localStorage.getItem('user_auth_data'));

    // if (userAuthData && userAuthData.user_token) {
    //     config.headers.common['access-token'] = userAuthData.user_token;
    //     config.headers.common['admin'] = userAuthData.admin;
    //     config.headers.common['expiry'] = userAuthData.expiry;
    //     config.headers.common['uid'] = userAuthData.uid;
    //     config.headers.common['token-type'] = userAuthData.token_type;
    // }

    dispatch(startLoading());
    return config;
}, error => {
    dispatch(getError(error));
    return Promise.reject(error);
});

//Add a response interceptor for user
userAxios.interceptors.response.use(response => {
    dispatch(clearErrors());
    dispatch(stopLoading());
    return response;
}, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        // toast.error("An unexpected error occured!");
    } else {
        dispatch(getError(error));
    }
    dispatch(clearErrors());
    dispatch(stopLoading());
    return Promise.reject(error);
});

//Add a request interceptor for admin

adminAxios.interceptors.request.use(config => {
    let adminAuthData = localStorage.token !== "undefined" && JSON.parse(localStorage.getItem('admin_data'));
    // if (adminAuthData && adminAuthData?.token) {
    //     config.headers.common['Authorization'] = `Bearer ${adminAuthData?.token}`;
    // }
    dispatch(startLoading());
    return config;
}, error => {
    dispatch(getError(error));
    return Promise.reject(error);
});

//Add a response interceptor for admin
adminAxios.interceptors.response.use(response => {
    dispatch(clearErrors());
    dispatch(stopLoading());
    return response;
}, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        // toast.error("An unexpected error occured!");
    } else {
        dispatch(getError(error));
    }
    dispatch(clearErrors());
    dispatch(stopLoading());
    return Promise.reject(error);
});

//Add a request interceptor for public

publicAxios.interceptors.request.use(config => {
    dispatch(startLoading());
    return config;
}, error => {
    dispatch(getError(error));
    return Promise.reject(error);
});

//Add a response interceptor for public
publicAxios.interceptors.response.use(response => {
    dispatch(clearErrors());
    dispatch(stopLoading());
    return response;
}, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        // toast.error("An unexpected error occured!");
    } else {
        dispatch(getError(error));
    }
    dispatch(clearErrors());
    dispatch(stopLoading());
    return Promise.reject(error);
});
//Start Loading
export const startLoading = () => {
    return {
        type: START_LOADING
    };
};


//Stop Loading
export const stopLoading = () => {
    return {
        type: STOP_LOADING
    };
};

