import React, { useState } from "react";
import { ArrowRightAltRounded, ArrowDownwardRounded } from "@material-ui/icons";
import ReferralTree from "./ReferralTree";

const ReferralLevel = ({ user, index, level, data }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const children =
    data[`level${level + 1}`]?.filter(
      (child, _) => child?.parent === user?.user
    ) || [];

  console.log(children.length);

  // Function to generate shades of RGB color
  function generateShade(baseColor, increment) {
    const shadeStep = 20; // Adjust this value to control the shade intensity
    const [r, g, b] = baseColor.split(",").map(Number); // Extract RGB values

    // Calculate new RGB values for the shade
    const newR = Math.min(255, r + shadeStep * increment);
    const newG = Math.min(255, g + shadeStep * increment);
    const newB = Math.min(255, b + shadeStep * increment);

    // Return the new shade as a string
    return `rgb(${newR},${newG},${newB})`;
  }

  return (
    <div
      className="d-flex flex-column level-container"
      style={{
        position: "relative",
        // backgroundColor: generateShade("18,73,29", level - 1),
        // zIndex: level,
      }}
    >
      <div
        className="level-bar"
        style={{ opacity: isExpanded ? "100" : "0" }}
      ></div>
      <div
        key={index}
        className="w-full d-flex flex-row align-items-center p-3 gap-1"
        style={{
          borderRadius: "39px",
        }}
      >
        {children?.length > 0 && (
          <div
            className={`expand-arrow ${isExpanded && "ref-expand"}`}
            style={{ cursor: "pointer" }}
            onClick={() => setIsExpanded(!isExpanded)} 
          >
            <ArrowDownwardRounded />
          </div>
        )}
        <p
          style={{
            marginLeft: "10px",
            marginBottom: "0px",
          }}
        >
          {user?.name}
        </p>
        {index === 0 && (
          <div className="d-flex w-100 justify-content-end">level {level}</div>
        )}
      </div>
      {isExpanded && (
        <div className="ms-5">
          <ReferralTree user={user?.user} data={data} level={level} />
        </div>
      )}
    </div>
  );
};

export default ReferralLevel;
