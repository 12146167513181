import React, { Component } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillInstagram,
} from "react-icons/ai";

const footer = () => <div className="footer">
  <div className="inner_box">
    <h1>follow us</h1>
    <div className="social_links">
      <a href="https://web.facebook.com/Sativa.Holding/?_rdc=1&_rdr">
        <AiFillFacebook size="42px" className="icon" />
      </a>
      <a href="https://twitter.com/SativaHolding">
        <AiFillTwitterSquare size="42px" className="icon" />
      </a>
      <a href="http://t.me/Sativa_HoIding">
        <FaTelegramPlane size="42px" className="icon" />
      </a>
      <a href="https://www.instagram.com/sativa_holding/">
        <AiFillInstagram size="42px" className="icon" />
      </a>
    </div>
  </div>
</div>

export default footer;
