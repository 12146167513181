import axios from "axios";
import { API_URL } from "./constants";

const adminAxios = axios.create({
  baseURL: API_URL
});

const userAxios = axios.create({
  baseURL: API_URL
});

const publicAxios = axios.create({
  baseURL: API_URL
});

export {
  adminAxios,
  userAxios,
  publicAxios
}; 