import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import bg_1 from "../img/bg_1.jpg";
import WeedLogo from "../img/WeedLogo.png";
import weed_pot_topview_min from "../img/weed_pot_topview-min.jpg";
import Tom_Kruesopon_Hemp_House from "../img/Tom-Kruesopon-Hemp-House.jpg";
import about_bg from "../img/about_bg.png";
import NavBar from "./NavBar";
import farming_2 from "../img/farming_2.png";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import InvestorDeck from "./InvestorDeck";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const Landing_page = () => {
  const { t, i18n } = useTranslation();
  console.log(t);
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/user/investment-overview");
      window.location.reload();
    }else{
      history.push("/user/login");
      window.location.reload();
    }
  });

  return (
    <>
      <NavBar />
      <div className="landing_page">
        <div className="top_section">
          <div className="inner_box">
            <div>
              <h1>{t("CANNABIS FARMING")}</h1>
              <h1>{t("INVESTMENT")}</h1>
              <p>{t("By Sativa Holding")}</p>
            </div>

            <Button>{t("Learn more")}</Button>
          </div>
        </div>
        <div className="section_3">
          <div className="inner_box">
            <div className="about">
              <h5>{t("ABOUT")}</h5>
              <p>{t("AboutLabel")}</p>
            </div>
            <div className="img_right">
              <img src={about_bg} alt="" />
            </div>
          </div>
        </div>
        <div className="section_4">
          <div className="left_side">
            <h4>{t("MARKET OVERVIEW")}</h4>
            <div className="inner_box">
              <div className="inner_left">
                <div className="items">
                  <img src={WeedLogo} alt="Weed Logo" />
                  <p>{t("WeedLogo")}</p>
                </div>
                <div className="items">
                  <img src={WeedLogo} alt="Weed Logo" />
                  <p>{t("Label2")}</p>
                </div>
                <div className="items">
                  <img src={WeedLogo} alt="Weed Logo" />
                  <p>{t("Label3")}</p>
                </div>
              </div>
              <div className="inner_right">
                <div className="items">
                  <img src={WeedLogo} alt="Weed Logo" />
                  <p>{t("Label4")}</p>
                </div>
                <div className="items">
                  <img src={WeedLogo} alt="Weed Logo" />
                  <p>{t("Label5")}</p>
                </div>
                <div className="items">
                  <img src={WeedLogo} alt="Weed Logo" />
                  <p>{t("Label6")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section_5">
          <div className="inner_box">
            <div className="farming_img"></div>
            <div className="farming_content">
              <h1>{t("FARMING")}</h1>
              <p>{t("Label7")}</p>
            </div>
            <img className="farming_2" src={farming_2} alt="" />
          </div>
        </div>
        <div className="section_6">
          <div className="content_area">
            <p>{t("Label8")}</p>
            <p className="mb-0">{t("Label9")}</p>
          </div>
        </div>
        <div className="section_7">
          <div className="inner_box">
            <InvestorDeck />
            <Button>{t("INVEST WITH US")}</Button>
            <p>{t("Label10")}</p>
          </div>
        </div>
        <div className="section_8">
          <div className="content_area">
            <div className="inner_box">
              <p>{t("Label11")}</p>
              <p>{t("Label12")}</p>
              <p>{t("Label13")}</p>
              <p>{t("Label14")}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Landing_page;
