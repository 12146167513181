import React, { useEffect, useState } from "react";
import "./User_Login.scss";
import UserNavBar from "../UserPanelNavBar";
import Input from "../../Common/Input";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotUser } from "../actions/signupActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  user_name: yup.string().required("User Name is a required field."),
});

const UserForgot = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state) => state.loading);
  const { backend_errors } = useSelector((state) => state.error);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: { user_name: "" },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (backend_errors) {
      setError(backend_errors);
    }
  }, [backend_errors]);

  const _fogot = (data) => {
    setError(null);
    data.user_name = data.user_name.toLowerCase();
    dispatch(forgotUser(data, history));
  };
  const { t } = useTranslation();

  return (
    <>
      <UserNavBar />
      <form onSubmit={handleSubmit((data) => _fogot(data))}>
        <div className="user-login mt-5">
          <div className="login-card">
            <div className="login-section">
              <h2>{t("Forgot Password")}</h2>
              {error?.message && (
                <span className="fields" style={{ color: "red" }}>
                  {error?.message && error?.message}
                </span>
              )}
              <Input
                label={t("User Name")}
                errors={errors?.user_name?.message}
                type="user_name"
                name="user_name"
                register={register}
              />
              <div className="login-btn">
                {!loading ? (
                  <button type="submit">{t("Submit")}</button>
                ) : (
                  <button type="button">
                    <div className="loader"></div>
                  </button>
                )}
              </div>
              <div className="optional-actions">
                <div>
                  <a href="/user/login">{t("LOGIN")}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UserForgot;
