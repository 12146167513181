import React, { useEffect, useState } from "react";
import "./User_Signup.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../Common/Input";
import { signup } from "../actions/signupActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserNavBar from "../UserPanelNavBar";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const schema = yup.object().shape({
  user_name: yup.string().required("User Name is a required field."),
  first_name: yup.string().required("First Name is a required field."),
  last_name: yup.string().required("Last Name is a required field."),
  email: yup
    .string()
    .required("Email is a required field.")
    .email("Insert a valid email address."),
  phone_number: yup.string().required("Phone Number is a required field."),
  referral_number: yup
    .string()
    .required("Referral Number is a required field."),
  password: yup.string().required("Password is a required field."),
  confirm_password: yup
    .string()
    .required("Confirm Password is a required field."),
});

const Admin_Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state) => state.loading);
  const { backend_errors } = useSelector((state) => state.error);
  const [error, setError] = useState(null);
  const [refCode, setReferralCode] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    values: {
      user_name: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      confirm_password: "",
    },
    resolver: yupResolver(schema),
  });
  const location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const ref = query.get("referral");
    if (ref) {
      setValue("referral_number", ref);
    }
  }, [location, setValue]);

  const _signup = (data) => {
    data.user_name = data.user_name.toLowerCase();
    if (data?.password === data?.confirm_password) {
      setError(null);
      dispatch(signup(data, history));
    } else {
      alert("Password and Confirm password should be same.");
    }
  };

  useEffect(() => {
    if (backend_errors) {
      setError(backend_errors);
    }
  }, [backend_errors]);

  const { t } = useTranslation();

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <UserNavBar />
      <form onSubmit={handleSubmit((data) => _signup(data))}>
        <div className="user-signup mt-5">
          <div className="signup-card">
            <div className="signup-section">
              <h2>{t("Sign Up")}</h2>
              {error?.message && (
                <span className="fields" style={{ color: "red" }}>
                  {error?.message && error?.message}
                </span>
              )}
              <Input
                label={t("User Name")}
                errors={errors?.user_name?.message}
                type="user_name"
                name="user_name"
                register={register}
              />
              <Input
                label={t("First Name")}
                errors={errors?.first_name?.message}
                type="first_name"
                name="first_name"
                register={register}
              />
              <Input
                label={t("Last Name")}
                errors={errors?.last_name?.message}
                type="last_name"
                name="last_name"
                register={register}
              />
              <Input
                label={t("Email")}
                errors={errors?.email?.message}
                type="email"
                name="email"
                register={register}
              />
              <Input
                label={t("Phone")}
                errors={errors?.phone_number?.message}
                type="phone_number"
                name="phone_number"
                register={register}
              />
              <Input
                errors={errors?.referral_number?.message}
                value={refCode}
                onChange={(e) => setReferralCode(e.target.value)}
                type="referral_number"
                name="referral_number"
                register={register}
                label={t("Referral Number")}
              />
              <div className="password-field" style={{ position: "relative" }}>
                <Input
                  errors={errors?.password?.message}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  register={register}
                  label={t("Password")}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="toggle-password-visibility"
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
              <div className="password-field" style={{ position: "relative" }}>
                <Input
                  errors={errors?.confirm_password?.message}
                  type={showPassword ? "text" : "password"}
                  name="confirm_password"
                  register={register}
                  label={t("Confirm Password")}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="toggle-password-visibility"
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
              <div className="signup-btn">
                {!loading ? (
                  <button type="submit">{t("Sign Up")}</button>
                ) : (
                  <button type="button">
                    <div className="loader"></div>
                  </button>
                )}
              </div>
              <p className="text-center mt-2">
                <b>Or</b>
                <br />
                <a href="/user/login">{t("LOGIN")}</a>
              </p>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default Admin_Signup;
