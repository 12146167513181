import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const UserPrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return auth.isAuthenticated ? (<Component {...props} />)
        : (<Redirect to={{ pathname: '/user/login', state: { from: props.location } }} />)
    }}
  />
);

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(UserPrivateRoute);