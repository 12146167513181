import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import story1 from "../img/story1.png";
// import story2 from "../img/story2.png";
// import bignner_guid from "../img/bignner_guid.png";
// import story5 from "../img/story5.png";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import { getLotsNumber } from "./UserPanel//actions/investActions";



const InvestorDeck = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { lots_details } = useSelector(state => state.history);
  const [digits, setAvail] = useState([]);
  const [total, setTotal] = useState([1, 5, 0, 0]);

  const divideNumberIntoDigits = () => {
    let digitArray = lots_details?.available_lots?.toString().split('');
    let totalArr = lots_details?.total_lots?.toString().split('');
    console.log("digits ", digitArray);
    setAvail(digitArray);
    setTotal(totalArr);
  };
  console.log("lots ", lots_details);
  useEffect(() => {
    dispatch(getLotsNumber())
    divideNumberIntoDigits()
  }, [lots_details?.available_lots == undefined])
  return (
    <>
      {/* <NavBar /> */}

      <div className="investor-deck">
        <h2 className="text-center my-3">Total Lots</h2>
        <div className="mb-4 main-digi">

          {total?.map((digit, index) => (
            <li style={{ listStyleType: 'none' }} key={index}>
              <div className="digital-wrapper mx-auto">
                <h5 className="m-0 digital-text">
                  {digit}
                </h5>
              </div>
            </li>
          ))}

        </div>

        <h2 className="text-center my-3">Available Lots</h2>
        <div className="mb-4 main-digi">

          {digits && digits.map((digit, index) => (
            <li style={{ listStyleType: 'none' }} key={index}>
              <div className="digital-wrapper mx-auto">
                <h5 className="m-0 digital-text">
                  {digits[index]}
                </h5>
              </div>
            </li>
          ))}

        </div>
      </div>
      {/* <Footer /> */}
    </>
  )

}

export default InvestorDeck;
