import React from "react";
import { withRouter } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router-dom";
import Admin_Login from "../../components/AdminPanel/AdminLogin/Admin_Login";
import Inbox from "../../components/AdminPanel/Inbox/Inbox";
import Investor_Details from "../../components/AdminPanel/InvestorDetails/Investor_Details";
import ReferralCommission from "../../components/AdminPanel/ReferralCommission/index";
import Transactions from "../../components/AdminPanel/Transactions/Transactions";
import AdminPrivateRoute from "./AdminPrivateRoute";
import TransactionHistoy from "../../components/AdminPanel/TransactionHistory/TransactionHistory";
import UserHistory from "../../components/AdminPanel/UserHistory";
import UserDetails from "../../components/AdminPanel/userDetails";

const AdminRoutes = () => {
  return (
    <Switch>
      <Route path="/admin/login" component={Admin_Login} />
      <AdminPrivateRoute
        path="/admin/investor-details"
        component={Investor_Details}
      />
      <AdminPrivateRoute path="/admin/inbox" component={Transactions} />
      {/* <AdminPrivateRoute path="/admin/inbox" component={Inbox} /> */}
      <AdminPrivateRoute
        path="/admin/referral-commissions"
        component={ReferralCommission}
      />
      <AdminPrivateRoute path="/admin/history" component={TransactionHistoy} />
      <AdminPrivateRoute path="/admin/user-history" component={UserHistory} />
      <AdminPrivateRoute path="/admin/user-details" component={UserDetails} />
      <Redirect from="/admin" to="/admin/login" />
    </Switch>
  );
};

export default withRouter(AdminRoutes);
