import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./utils/httpInterceptor";
import "./i18n";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});
const renderReactDom = () => {
  ReactDOM.render(
    <I18nextProvider i18n={i18next}>
      <Router>
        <App />
        <ToastContainer toastStyle={{ width: "150%" }} />
      </Router>
    </I18nextProvider>,
    document.getElementById("root")
  );
};

renderReactDom();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
