import React, { useEffect } from "react";
import "./index.scss";
import AdminNavBar from "../AdminNavBar";
import { getRefralCommissionList } from "../actions/investorsActions";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from "react-i18next";

const Index = () => {
  const dispatch = useDispatch();
  const { investment_refral_commissions } = useSelector(state => state?.investments);
  const { loading } = useSelector(state => state?.loading);
  useEffect(() => {
    dispatch(getRefralCommissionList());
  }, [])

  const { t, i18n } = useTranslation();
  console.log(t)
  return (
    <>
      <AdminNavBar />
      <div className="investor-section">
        <div className="investor-content">
          <h2>{t("Investors")}</h2>
          <div className="outer-shadow">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>{t("Name")}</th>
                    <th>{t("Referral")} #</th>
                    <th>{t("Investment Value")} ({t("USDT")})</th>
                    <th>{t("Referral Commission")} ({t("USDT")})</th>
                    <th>{t("Interest Earned")} ({t("USDT")})</th>
                    <th>{t("Withdrawals")} ({t("USDT")})</th>
                  </tr>
                </thead>
                {!loading ? investment_refral_commissions?.map(item => (
                  <tbody>
                    <tr>
                      {console.log(item)}
                      <td>{item?.user_id?.first_name + " " + item?.user_id?.last_name}</td>
                      <td>{item?.refral_code}</td>
                      <td>20,000</td>
                      <td>0</td>
                      <td>1,000</td>
                      <td>400</td>
                    </tr>
                  </tbody>)) :
                  loading ? [...Array(10)].map(() => (
                    <tr>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                    </tr>
                  )) :
                    <div>
                      {t("No Record Found")}
                    </div>
                }
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
