import React, { useState, useEffect } from "react";
import "./Investor_Details.scss";
import AdminNavBar from "../AdminNavBar";
import {
  listOfInvestors,
  setUserDummy,
  userRCHistory,
  userRefralTree,
} from "../actions/investorsActions";
import { useDispatch, useSelector } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Modal, Table } from "react-bootstrap";
import { REFRAL_TREE } from "../../../utils/types";
import LevelUsers from "../../UserPanel/LevelUsers";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReferralTree from "../../UserPanel/ReferralTree";

const Investor_Details = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { investors, refralTree } = useSelector((state) => state?.investors);
  const [modifiedInvestors, setModifiedInvestors] = useState([]);
  const [open, setOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedUserUserName, setselectedUserUserName] = useState("");
  const [selectedUser, setselectedUser] = useState({});
  const [rows, setRows] = useState(null);
  const [header, setHeader] = useState(null);
  const [treeNull, setTreeNull] = useState(false);
  const [referralTree, setReferralTree] = useState(null);
  const [maxLevels, setMaxLevels] = useState(0);
  const { user_name } = useSelector((state) => state?.adminAuth?.admin?.user);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(listOfInvestors());
  }, []);

  useEffect(() => {
    if (investors?.length > 0 || modifiedInvestors?.length > 0) {
      console.log(investors);
      console.log(modifiedInvestors);
      const mInvestors = investors.map((inv) => ({
        ...inv,
        "show-refferal": (
          <div className="d-flex gap-3">
            <Button onClick={() => showRefferal(inv._id, inv.user_name)}>
              Show
            </Button>
          </div>
        ),
        "show-history": (
          <div className="d-flex gap-3">
            <Button onClick={() => showUserHistory(inv._id, inv.user_name)}>
              Show History
            </Button>
          </div>
        ),
        refral_code: (
          <>
            {inv?.parent_id ? (
              <td>{inv?.parent_id?.user_name}</td>
            ) : (
              <td>Not reffered</td>
            )}
          </>
        ),
        "set-dummy": (
          <>
            {inv?.isDummy ? (
              <td>Is Dummy</td>
            ) : (
              <div className="d-flex gap-3">
                <Button onClick={() => handleConfirmOpen(inv)}>
                  Set Dummy
                </Button>
              </div>
            )}
          </>
        ),
        isDummy: <td>{inv?.isDummy ? <>Yes</> : <>No</>}</td>,
      }));
      setModifiedInvestors(mInvestors);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [investors]);

  function setLevels(data) {
    const topLevelUsers = data["level1"];
    if (!topLevelUsers) {
      setTreeNull(true);
      return [];
    }
    Object.keys(data).map((level, index) => {
      if (data[level] !== null && data[level]?.length !== 0) {
        setMaxLevels(index + 1);
      }
    });
  }

  console.log(treeNull);

  useEffect(() => {
    if (Object.keys(refralTree).length > 0) {
      const referralCHG = refralTree;

      console.log(referralCHG);
      if (referralCHG.level1 === null || referralCHG.level1?.length === 0) {
        setTreeNull(true);
      } else {
        console.log(treeNull);
        console.log(referralCHG);
        setLevels(referralCHG);
        setReferralTree(referralCHG);
      }

      setLoading(false);
      handleOpen();
    }
  }, [refralTree]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleConfirmOpen = (user) => {
    setselectedUser(user);
    setOpenConfirmModal(true);
  };
  const handleClose = () => {
    setselectedUserUserName("");
    dispatch({
      type: REFRAL_TREE,
      payload: [],
    });
    setOpen(false);
  };
  const handleConfirmClose = () => {
    setOpenConfirmModal(false);
  };

  const [referralTreeOf, setRefferelTreeOf] = useState("");

  const showRefferal = (id, UserName) => {
    setLoading(true);
    setselectedUserUserName(UserName);
    setRefferelTreeOf(id);
    dispatch(userRefralTree(id));
  };

  const getLevels = () => {
    let levs = [];
    for (let levels = 1; levels <= maxLevels; levels++) {
      levs.push(levels);
    }
    return (
      <>
        {levs.map((level, index) => (
          <div
            key={index}
            style={{ height: "3rem", borderBottom: "1px solid black" }}
            className="d-flex flex-column justify-content-center"
          >
            <h6 className="">Level {level}</h6>
          </div>
        ))}
      </>
    );
  };

  const columns = [
    {
      name: "user_name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "isDummy",
      label: "Is Dummy",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "refral_code",
      label: " Direct Referral",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "wallet",
      label: "Investment Value (USDT)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "refral_earned",
      label: "Registration Point (USDT)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "interest_earned",
      label: "Interest Earned (USDT)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "total_widrawl",
      label: "Withdrawals (USDT)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "show-refferal",
      label: "Refferal Tree",
    },
    {
      name: "show-history",
      label: "Investor History",
    },
    {
      name: "set-dummy",
      label: "Action",
    },
  ];

  const showUserHistory = (id, UserName) => {
    const userDetails = {
      id,
      UserName,
    };
    history.push({
      pathname: "/admin/user-history",
      state: { userDetails: userDetails },
    });
    // dispatch(userRCHistory(id));
  };

  const { t, i18n } = useTranslation();
  console.log(t);
  return (
    <>
      {loading && (
        <div id="loader" className="loader-overlay">
          <div className="loader-box">
            <div id="preLoader" />
          </div>
        </div>
      )}
      <AdminNavBar />
      <div className="investor-section">
        <div className="investor-content">
          <h2>{t("Investors")}</h2>
          <MUIDataTable
            title={"Investors"}
            data={modifiedInvestors}
            columns={columns}
          // options={options}
          />
        </div>
      </div>

      <Modal show={open} onHide={handleClose}>
        <Modal.Body>
          <div className="trans-content interest-earned-btn ref-table">
            <h3>{t(`Refferal Commission Tree Of ${selectedUserUserName}`)}</h3>
            {loading ? (
              <Skeleton />
            ) : (
              <div className="p-2" style={{ borderRadius: "23px" }}>
                <ReferralTree
                  user={referralTreeOf}
                  data={refralTree}
                  level={0}
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={openConfirmModal} onHide={handleConfirmClose}>
        <Modal.Body>
          <div className="trans-content interest-earned-btn ref-table">
            <h1>{t(`Set ${selectedUser?.user_name} as dummy user?`)}</h1>
            <div className="d-flex gap-3">
              <Button
                variant="secondary"
                onClick={() => setUserDummy(true, selectedUser?._id, user_name)}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Investor_Details;
