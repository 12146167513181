import {
  GET_INVESTORS,
  GROUP_BONUS_HISTORY,
  INTERST_HISTORY,
  REFRAL_HISTORY,
  REFRAL_TREE,
} from "../../../utils/types";

const initialState = {
  investors: [],
  refralTree: [],
  referralHistory: undefined,
  interestHistory: undefined,
  groupBonusHistory: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INVESTORS:
      return {
        ...state,
        investors: action.payload,
      };
    case REFRAL_TREE:
      return {
        ...state,
        refralTree: action.payload,
      };
    case REFRAL_HISTORY:
      return {
        ...state,
        referralHistory: action.payload,
      };
    case INTERST_HISTORY:
      return {
        ...state,
        interestHistory: action.payload,
      };
    case GROUP_BONUS_HISTORY:
      return {
        ...state,
        groupBonusHistory: action.payload,
      };
    default:
      return state;
  }
}
