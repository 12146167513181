import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SATIVA_Logo from "../../img/SATIVA-Logo.png";
import mac from "../../img/mac.png";
import agro2 from "../../img/agro2.png";
import { Tab } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  handleReportDownload,
  downloadFile,
  logoutAdmin,
  updateNotifications,
} from "./actions/adminLoginAction";
import { useTranslation } from "react-i18next";
import { FaBell } from "react-icons/fa";
import { notificationMessages } from "../../utils/types";
import { showToast } from "../../utils/toast";

const NavBar = () => {
  const dispatch = useDispatch();

  const _logout = () => {
    dispatch(logoutAdmin());
  };

  const [nots, setNots] = useState(
    useSelector((state) => state?.adminAuth?.notifications)
  );

  const [readNots, setReadNots] = useState([]);
  const [unReadNots, setUNReadNots] = useState([]);

  useEffect(() => {
    dispatch(fetchNotifications(setNots, nots));
  }, []);

  useEffect(() => {
    if (nots?.length > 0) {
      const rNots = nots?.filter((not, _) => not?.readStatus);
      const urNots = nots?.filter((not, _) => !not?.readStatus);
      setUNReadNots(urNots);
      setReadNots(rNots);
    }
  }, [nots]);

  const [showNots, setShowNots] = useState(false);

  const showNotifications = () => {
    setShowNots(true);
    const nL = nots?.filter((not, _) => !not?.readStatus);
    if (nL?.length > 0) {
      dispatch(updateNotifications(nots));
    }
  };

  const notRef = useRef(null);

  // Close the modal when clicking outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (notRef.current && !notRef.current.contains(event.target)) {
        setShowNots(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowNots]);

  console.log(showNots);

  const getUnreadNotsLength = () => {
    const nL = nots?.filter((not, _) => !not?.readStatus);
    return nL?.length;
  };

  const handleUnreadMessages = (k) => {
    console.log(k);
    if (k === "unread") {
      if (unReadNots?.length > 0) {
        dispatch(updateNotifications(unReadNots));
      } else {
        console.log("no notifications to read");
      }
    }
  };

  const { t, i18n } = useTranslation();
  console.log(t);

  const currentUser = useSelector((state) => state?.adminAuth?.admin?.user);
  return (
    <Navbar bg="" expand="md">
      <Container fluid>
        <Link className="navbar-brand" to="/">
          <div className="d-flex align-items-center logos">
            <img src={mac} alt="" style={{ paddingTop: "10px" }} />
            <div className="d-flex flex-column">
              {/* <img src={agro} alt="" /> */}
              <img className="partner-logo" src={agro2} alt="" />
              <span className="partner-text text-center">
                Our Marketing Arm
              </span>
            </div>
          </div>
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse className="ml-auto">
        {currentUser?.user_name === "admin" && (
          <div
            className="nav-link"
            style={{ cursor: "pointer" }}
            onClick={() => downloadFile()}
          >
            Download Weekly Report
          </div>
        )}
          {currentUser?.user_name === "admin" && (
          <div class="dropdown" style={{ cursor: "pointer" }}>
            <div
              class="nav-link position-relative"
              onClick={() => showNotifications()}
            >
              <FaBell color={showNots ? "#000000" : "#12491d"} />
              {unReadNots?.length > 0 && (
                <span
                  class={`position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ${
                    nots?.length > 0 ? "block" : "visually-hidden"
                  }`}
                >
                  {unReadNots?.length}
                  <span class="visually-hidden">unread messages</span>
                </span>
              )}
            </div>
            <div
              class="dropdown-menu overflow-auto p-0"
              aria-labelledby="dropdownMenuButton"
              ref={notRef}
              style={{
                display: showNots ? "block" : "none",
                maxHeight: "600px",
              }}
            >
              <Tabs
                variant="underline"
                defaultActiveKey={unReadNots?.length > 0 ? "unread" : "read"}
                id="uncontrolled-tab-example"
                className="my-2 border-0"
                onSelect={(k) => handleUnreadMessages(k)}
              >
                <Tab eventKey="read" title="read">
                  <div className="w-full d-flex flex-column">
                    {readNots?.map((not, index) => (
                      <div
                        key={index}
                        className="d-flex flex-column gap-1 p-2 border-bottom"
                      >
                        <div className="w-full">{not?.type}</div>
                        <p className="m-0">
                          {new Date(not?.time)?.toLocaleString()}
                        </p>
                      </div>
                    ))}
                  </div>
                </Tab>
                <Tab eventKey="unread" title="unread">
                  <div className="w-full d-flex flex-column">
                    {unReadNots?.length < 1 ? (
                      <div className=" d-flex justify-content-center w-full">
                        <p className="m-0">Notification Box empty</p>
                      </div>
                    ) : (
                      <React.Fragment>
                        {unReadNots?.map((not, index) => (
                          <div
                            key={index}
                            className="d-flex flex-column gap-1 p-2 border-bottom"
                          >
                            <div className="w-full">{not?.type}</div>
                            <p className="m-0">
                              {new Date(not?.time)?.toLocaleString()}
                            </p>
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          )}
          <Nav className="my-2 my-lg-0 align-items-center align-items-md-start">
            <Link className="nav-link mx-4" to="/admin/investor-details">
              {t("Investors")}
            </Link>
            {currentUser?.user_name === "admin" && (
            <Link className="nav-link mx-4" to="/admin/inbox">
              {t("Inbox")}
            </Link>
            )}
            {/* <Link className="nav-link mx-4" to="/admin/inbox">
              {t("Inbox")}
            </Link> */}
             {currentUser?.user_name === "admin" && (
            <Link className="nav-link mx-4" to="/admin/history">
              {t("History")}
            </Link>
            )}
            <Link className="nav-link mx-4" to="/admin/user-details">
              {t("UserDetails")}
            </Link>
            <Link className="nav-link mx-4" onClick={() => _logout()}>
              {t("Logout")}
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
