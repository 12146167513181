import React from "react";
const Selector = ({
  classNames,
  label,
  options,
  defaultOption,
  type,
  name,
  errors,
  register,
  value,
  ...rest
}) => {
  console.log(value);
  return (
    <>
      <div
        className={`country form-group ${
          classNames ? classNames : ""
        } d-flex align-items-center`}
      >
        <label>{label}</label>
        <select
          {...rest}
          {...register(name, {
            // required: require,
          })}
          value={value && value}
          className="form-control"
        >
          <option value="">{defaultOption}</option>
          {options?.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      {errors && (
        <span className="fields" style={{ color: "red" }}>
          {errors && errors}
        </span>
      )}
    </>
  );
};
export default Selector;
