import { combineReducers } from 'redux';
import adminLoginReducer from '../components/AdminPanel/reducers/adminLoginReducer';
import investorsReducer from '../components/AdminPanel/reducers/investorsReducer';
import investmentsReducer from '../components/AdminPanel/reducers/investmentsReducer';
import errorReducer from '../components/Common/reducers/errorReducer';
import loadingReducer from '../components/Common/reducers/loadingReducer';
import loginReducer from '../components/UserPanel/reducers/loginReducer';
import investmentUserReducer from '../components/UserPanel/reducers/investReducer';
import overviewReducer from '../components/UserPanel/reducers/overviewReducer';

export default combineReducers({
  auth: loginReducer,
  error: errorReducer,
  loading: loadingReducer,
  adminAuth: adminLoginReducer,
  investors: investorsReducer,
  investments: investmentsReducer,
  history: investmentUserReducer,
  overview: overviewReducer
});