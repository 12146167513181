import { userAxios } from "../../config/config";

const setUserData = authData => {
    if (authData) {
        userAxios.defaults.headers.common['token'] = authData.token;
    } else {
        delete userAxios.defaults.headers.common['token'];
    }
};

export default setUserData;