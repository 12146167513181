import React, { useEffect, useState } from "react";
import AdminNavBar from "../AdminNavBar";
import { useDispatch, useSelector } from "react-redux";
import {
  userGPHistory,
  userICHistory,
  userRCHistory,
} from "../actions/investorsActions";
import { ArrowLeft } from "@mui/icons-material";
import "../InvestorDetails/Investor_Details.scss";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UserHistory = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = props;
  const userDetails = location.state.userDetails;

  const [loading, setLoading] = useState(false);
  const [historyType, setHistoryType] = useState("RC");
  const [userReferralHistory, setUserReferralHistory] = useState([]);
  const [userReferralWithdrawals, setUserReferralWithdrawals] = useState([]);
  const [userInterestHistory, setUserInterestHistory] = useState([]);
  const [userInterestWithdrawals, setUserInterestWithdrawals] = useState([]);
  const [userGroupBonusWithdrawals, setUserGroupBonusWithdrawals] = useState(
    []
  );

  const { referralHistory, interestHistory, groupBonusHistory } = useSelector(
    (state) => state?.investors
  );

  const getDate = (date) => {
    const currentDate = new Date(date);

    const readableDate = currentDate.toDateString();
    const time = currentDate.toLocaleTimeString();

    return readableDate ;
  };

  useEffect(() => {
    console.log("referralHistory", referralHistory);
    if (referralHistory !== undefined) {
      const mRC = referralHistory?.referralCommissions.map((inv) => ({
        ...inv,
        created_at: getDate(inv.createdAt),
        updated_at: getDate(inv.updatedAt),
      }));
      setUserReferralHistory(mRC);
      const mRW = referralHistory?.referralWithdrawals.map((inv) => ({
        ...inv,
        created_at: getDate(inv.createdAt),
        updated_at: getDate(inv.updatedAt),
      }));
      setUserReferralWithdrawals(mRW);
    }
  }, [referralHistory]);
  useEffect(() => {
    console.log("interestHistory", interestHistory);
    if (interestHistory !== undefined) {
      const mRC = interestHistory?.interestCommissions.map((inv) => ({
        ...inv,
        created_at: getDate(inv.createdAt),
        affected_date: getDate(inv.affected_date),
      }));
      setUserInterestHistory(mRC);
      const mRW = interestHistory?.interestlWithdrawals.map((inv) => ({
        ...inv,
        created_at: getDate(inv.createdAt),
        affected_date: getDate(inv.affected_date),
      }));
      setUserInterestWithdrawals(mRW);
    }
  }, [interestHistory]);
  useEffect(() => {
    console.log("groupBonusHistory", groupBonusHistory);
    if (groupBonusHistory !== undefined) {
      const mRW = groupBonusHistory?.groupBonusWithdrawals.map((inv) => ({
        ...inv,
        created_at: getDate(inv.createdAt),
        updated_at: getDate(inv.updatedAt),
      }));
      setUserGroupBonusWithdrawals(mRW);
    }
  }, [groupBonusHistory]);

  useEffect(() => {
    dispatch(userRCHistory(userDetails.id));
    dispatch(userGPHistory(userDetails.id));
    dispatch(userICHistory(userDetails.id));
  }, []);

  const RCColumns = [
    {
      name: "account_name",
      label: "Account",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "percentage",
      label: "Percentage",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "reason",
      label: "Level",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "affected_date",
      label: "Affected Date",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const RColumns = [
    {
      name: "account_name",
      label: "Account",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "percentage",
      label: "Percentage",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "reason",
      label: "Level",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "updated_at",
      label: "Updated AT",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const RWColumns = [
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "wallet_address",
      label: "Wallet Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "network",
      label: "Network",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "updated_at",
      label: "Updated At",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <>
      {loading && (
        <div id="loader" className="loader-overlay">
          <div className="loader-box">
            <div id="preLoader" />
          </div>
        </div>
      )}
      <AdminNavBar />

      <div className="investor-section">
        <div className="d-flex w-100 justify-content-start p-3 mb-3">
          <button
            type="button"
            onClick={() => history.push("/admin/investor-details")}
            className="align-items-center d-flex"
          >
            <ArrowLeft /> Go Back
          </button>
        </div>
        <div className="investor-content">
          <h2>{userDetails.UserName}'s History</h2>
          <div className="d-flex justify-content-center gap-3 mb-5">
            <button
              className={historyType !== "RC" && "outline-button"}
              onClick={() => setHistoryType("RC")}
            >
              Referral Commission History
            </button>
            <button
              className={historyType !== "IC" && "outline-button"}
              onClick={() => setHistoryType("IC")}
            >
              Interest Commission History
            </button>
            <button
              className={historyType !== "GB" && "outline-button"}
              onClick={() => setHistoryType("GB")}
            >
              Group Bonus History
            </button>
          </div>
          {historyType === "RC" && (
            <>
              <div className="d-flex flex-wrap justify-content-center gap-5">
                <p>
                  <strong>Total Referral Commissions:</strong>{" "}
                  {referralHistory?.totalReferralCommissions}
                </p>
                <p>
                  <strong>Remaining Referral Earned:</strong>{" "}
                  {referralHistory?.remainingReferralEarned}
                </p>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <MUIDataTable
                  title={"Interest Commissions"}
                  data={userReferralHistory}
                  columns={RColumns}
                />
              </div>
              <div style={{ paddingLeft: "10px" }} className="mt-5 mb-5">
                <MUIDataTable
                  title={"Referral Withdrawals"}
                  data={userReferralWithdrawals}
                  columns={RWColumns}
                />
              </div>
            </>
          )}
          {historyType === "IC" && (
            <>
              <div className="d-flex flex-wrap justify-content-center gap-5">
                <p>
                  <strong>Total Interest Commissions:</strong>{" "}
                  {interestHistory?.totalinterestCommissions}
                </p>
                <p>
                  <strong>Remaining Interest Earned:</strong>{" "}
                  {interestHistory?.remainingInterestEarned}
                </p>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <MUIDataTable
                  title={"Interest Commissions"}
                  data={userInterestHistory}
                  columns={RCColumns.slice(1)}
                />
              </div>
              <div style={{ paddingLeft: "10px" }} className="mt-5 mb-5">
                <MUIDataTable
                  title={"Interest Withdrawals"}
                  data={userInterestWithdrawals}
                  columns={RWColumns}
                />
              </div>
            </>
          )}
          {historyType === "GB" && (
            <>
              <div className="d-flex flex-wrap justify-content-center gap-5">
                <p>
                  <strong>Total Group Bonus:</strong>{" "}
                  {groupBonusHistory?.totalGroupBonus}
                </p>
                <p>
                  <strong>Remaining Group Bonus:</strong>{" "}
                  {groupBonusHistory?.remainingGroupBonus}
                </p>
              </div>
              <div style={{ paddingLeft: "10px" }} className="mt-5 mb-5">
                <MUIDataTable
                  title={"Group Bonus Withdrawals"}
                  data={userGroupBonusWithdrawals}
                  columns={RWColumns}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserHistory;
