import {
  ALL_INVESTMENT,
  GET_INTERSET_COMMISSION_USER,
  GET_REFRAL_COMMISSION_TREE,
  GET_REFRAL_COMMISSION_USER,
  GET_REFRAL_COMMISSION_USER_LEVEL,
  GET_WITHDRAW_LIST,
  LOTS_NUMBERS,
} from "../../../utils/types";

const initialState = {
  history: [],
  withdraw: [],
  investment_refral_commissions: [],
  investment_interest_commissions: [],
  investment_refral_commissions_level: [],
  investment_refral_commissions_tree: [],
  lots_details: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ALL_INVESTMENT:
      return {
        ...state,
        history: action.payload,
      };
    case GET_WITHDRAW_LIST:
      return {
        ...state,
        withdraw: action.payload,
      };
    case LOTS_NUMBERS:
      return {
        ...state,
        lots_details: action.payload,
      };
    case GET_REFRAL_COMMISSION_USER:
      return {
        ...state,
        investment_refral_commissions: action.payload,
      };
    case GET_REFRAL_COMMISSION_USER_LEVEL:
      return {
        ...state,
        investment_refral_commissions_level: action.payload,
      };
    case GET_INTERSET_COMMISSION_USER:
      return {
        ...state,
        investment_interest_commissions: action.payload,
      };
    case GET_REFRAL_COMMISSION_TREE:
      return {
        ...state,
        investment_refral_commissions_tree: action.payload,
      };
    default:
      return state;
  }
}
