import React, { useEffect, useState } from "react";
import "./User_Login.scss";
import UserNavBar from "../UserPanelNavBar";
import Input from "../../Common/Input";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from '../../UserPanel/actions/loginActions'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";

const schema = yup.object().shape({
    password: yup.string().required("Password is a required field."),
    confirm_password: yup.string().required("Password is a required field."),
});

const User_Reset = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const param = useParams();
    const { loading } = useSelector(state => state.loading);
    const { backend_errors } = useSelector(state => state.error);
    const [error, setError] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        values: { confirm_password: "", password: "" },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (backend_errors) {
            setError(backend_errors)
        }
    }, [backend_errors])

    const _reset = (data) => {
        if (data?.password === data?.confirm_password) {
            const userData = {
                new_password: data?.password,
                confirm_password: data?.confirm_password,
                token: history?.location?.search?.split('token=')[1]
            }
            setError(null);
            dispatch(resetPassword(userData, history));
        } else {
            alert("password and confirm password should be same!")
        }
    }

    return (
        <>
            <UserNavBar />
            <form onSubmit={handleSubmit((data) => _reset(data))}>
                <div className="user-login mt-5">
                    <div className="login-card">
                        <div className="login-section">
                            <h2>Reset Password</h2>
                            {error?.message && <span className="fields" style={{ color: 'red' }}>{error?.message && error?.message}</span>}
                            <Input
                                errors={errors?.password?.message}
                                type="password"
                                name="password"
                                register={register}
                                label="Password"
                            />
                            <Input label="Confirm Password"
                                errors={errors?.confirm_password?.message}
                                type="password"
                                name="confirm_password"
                                register={register}
                            />
                            <div className="login-btn">
                                {!loading ?
                                    <button type="submit">Submit</button> :
                                    <button type="button"><div className="loader"></div></button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default User_Reset;