import React, { useEffect, useState } from "react";
import "./Admin_Login.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import SATIVA_Logo from "../../../img/SATIVA-Logo.png";
import mac from "../../../img/mac.png";
import agro2 from "../../../img/agro2.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginAdmin } from "../actions/adminLoginAction";
import Input from "../../Common/Input";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  user_name: yup.string().required("Email is a required field."),
  password: yup.string().required("Password is a required field."),
});

const Admin_Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state) => state.loading);
  const { backend_errors } = useSelector((state) => state.error);
  const [error, setError] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: { user_name: "", password: "" },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (backend_errors) {
      setError(backend_errors);
    }
  }, [backend_errors]);

  useEffect(() => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.register('/sw.js')
        .then(swReg => {
          console.log('Service Worker is registered', swReg);
          swReg.pushManager.getSubscription()
            .then(subscription => {
              if (subscription === null) {
                // Admin is not subscribed
                subscribeAdmin(swReg);
              } else {
                console.log('Admin is already subscribed', subscription);
                setSubscription(subscription);
              }
            });
        })
        .catch(error => {
          console.error('Service Worker Error', error);
        });
    }
  }, []);

  const subscribeAdmin = (swReg) => {
    const applicationServerKey = urlBase64ToUint8Array('BKDsmVNvuTFAg0sF-WbjKEBzLJqs5HVOxwXGEUOx0nzZsxcINY8MAtmbzO9U_Z_1mdqzzJXJ5fAouBAcRx93YD8');
    swReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    })
    .then(subscription => {
      console.log('Admin is subscribed', subscription);
      setSubscription(subscription);
    })
    .catch(err => {
      console.log('Failed to subscribe the admin: ', err);
    });
  };

  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  const _login = (data) => {
    setError(null);
    const loginData = { ...data, subscription };
    dispatch(loginAdmin(loginData, history));
  };

  const { t, i18n } = useTranslation();
  return (
    <>
      <form onSubmit={handleSubmit((data) => _login(data))}>
        <Navbar bg="" expand="md">
          <Container fluid>
            <Link className="navbar-brand" to="/">
              <div className="d-flex align-items-center logos">
                <img src={mac} alt="" />
                <div className="d-flex flex-column">
                  {/* <img src={agro} alt="" /> */}
                  <img className="partner-logo" src={agro2} alt="" />
                  <span className="partner-text text-center">
                    Our Marketing Arm
                  </span>
                </div>
              </div>
            </Link>
          </Container>
        </Navbar>
        <div className="admin-login">
          <div className="login-card">
            <div className="login-section">
              <h2>{t("Login")}</h2>
              {error?.message && (
                <span className="fields" style={{ color: "red" }}>
                  {error?.message && error?.message}
                </span>
              )}
              <Input
                label="User Name"
                errors={errors?.user_name?.message}
                type="user_name"
                name="user_name"
                register={register}
              />
              <Input
                errors={errors?.password?.message}
                type="password"
                name="password"
                register={register}
                label="Password"
              />
              <div className="login-btn">
                {!loading ? (
                  <button type="submit">{t("Login")}</button>
                ) : (
                  <button type="button">
                    <div className="loader"></div>
                  </button>
                )}
              </div>
              <div className="optional-actions d-none">
                <div>
                  <a href="/admin/signup">{t("Sign Up")}</a>
                </div>
                <div>
                  <a href="#">{t("Forget Password")}?</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Admin_Login;
