import { userAxios } from "../../../config/config";
import { showToast } from "../../../utils/toast";
import {
  ALL_INVESTMENT,
  GET_WITHDRAW_LIST,
  GET_OVERVIEW,
  GET_REFRAL_COMMISSION_USER,
  GET_INTERSET_COMMISSION_USER,
  GET_REFRAL_COMMISSION_USER_LEVEL,
  LOTS_NUMBERS,
  GET_REFRAL_COMMISSION_TREE,
} from "../../../utils/types";
  


  
  // Action to transfer registration point
  export const transferPoints = (recipientUsername, amount, setShowModal) => (dispatch) => {
    const object = {
      recipientUsername,
      amount,
    };
  
    userAxios.post('http://13.215.175.5:4000/v1/invester/investment/transfer-registration-points', object)
      .then((response) => {
        if (response.status === 200 && response.data && response.data.status) {
          // On success
          showToast('success', response.data.message || 'Points transferred successfully');
          setShowModal(false);
          
        } else if(response.status === 404 && response.data && response.data.status) {
          // On success
          showToast('error', response.data.message || 'Sender or Reciepient not found');
          
          
        }
      })
      .catch((error) => {
        // Handle network or server errors
        const errorMessage = error.response?.data?.message || 'An error occurred during the transfer';
         showToast('error', errorMessage);
         setShowModal(true);
        // setError(errorMessage); // Pass error message back to component
      });
  };
  

export const investAmount = (data, image, history, active) => (dispatch) => {
  var formData = new FormData();
  formData.append("image", image);
  formData.append("amount", parseInt(data?.amount, 10));
  formData.append("network", data?.network);
  formData.append("tax_hash", data?.hash);
  userAxios.post(`/invester/investment/invest`, formData).then((res) => {
    showToast("success", "Investment Successfully Submitted");
    history.push("/user/investment-overview");
  });
};

export const investAmountWithRP = (data, history) => (dispatch) => {
  const object = {
    amount: data?.amount,
  };
  userAxios.post(`/invester/investment/invest-with-RP`, object).then((res) => {
    showToast("success", "Investment Successfully Submitted");
    history.push("/user/investment-overview");
  });
};

export const getInverstmentList = () => (dispatch) => {
  userAxios.get(`/invester/investment`).then((res) => {
    dispatch({
      type: ALL_INVESTMENT,
      payload: res?.data?.payload?.investments,
    });
    //Inversted Successfully!
  });
};

export const getLotsNumber = () => (dispatch) => {
  userAxios.get(`/invester/investment/lots`).then((res) => {
    dispatch({
      type: LOTS_NUMBERS,
      payload: res?.data?.payload,
    });
    //Inversted Successfully!
  });
};
export const getListOfWithdraw = (status) => (dispatch) => {
  userAxios.get(`/invester/investment/get-withdrawl-requests`).then((res) => {
    // Save to localStorage
    dispatch({
      type: GET_WITHDRAW_LIST,
      payload: res?.data?.payload?.withdrawl_requests,
    });
  });
};

export const getOverview = () => (dispatch) => {
  userAxios.get(`/invester/investment/get-overview`).then((res) => {
    dispatch({
      type: GET_OVERVIEW,
      payload: res?.data?.payload?.overview,
    });
  });
};

export const withDraw = (data, history, setShowModal) => (dispatch) => {
  if (data.type == "Withdrawal") {
    const object = {
      amount: data?.amount,
      wallet_address: data?.address,
      network: data?.network,
      commissionType: data?.commissionType,
      // "country": data?.country,
      // "account_name": data?.account_holder,
      // "account_no": data?.bank_account,
      // "swift_code": data?.swift_code,
      // "type": data?.type
    };
    userAxios
      .post(`/invester/investment/wallet-withdrawl-request`, object)
      .then(() => {
        window.location.reload();
        setShowModal(true);
        // history.push('/user/investment-overview');
      })
      .catch((err) => {
        setShowModal(false);
      });
  } else {
    const values = history?.location?.state?.details;
    const object = {
      id: values?._id,
      amount: data?.amount,
      country: data?.country,
      account_name: data?.account_holder,
      network: data?.network,
      account_no: data?.bank_account,
      swift_code: data?.swift_code,
      type: data?.type == "Referral Commisssion" ? "referral" : "interest",
    };
    userAxios
      .post(`/invester/investment/commissions-withdrawl-request`, object)
      .then(() => {
        // history.push("/user/investment-overview");
      })
      .catch((err) => {});
  }
};

export const convertToRP = (data, setShowConverModal) => (dispatch) => {
  const object = {
    type: data.earningType,
    amount: data.amount,
  };

  userAxios
    .post(`/invester/investment/convert-registration-points`, object)
    .then(() => {
      showToast("success", "Conversion Successfull");
      getOverview();
      setShowConverModal(false);
      window.location.reload();
    })
    .catch((err) => {
      showToast("success", "Conversion Failed");
      setShowConverModal(false);
      window.location.reload();
    });
};

export const getRefralCommissionUserList = (status) => (dispatch) => {
  userAxios
    .get(`/invester/investment/get-refral-commission-list?status=${status}`)
    .then((res) => {
      dispatch({
        type: GET_REFRAL_COMMISSION_USER,
        payload: res?.data?.payload?.investment_refral_commissions,
      });
    });
};
export const getRefralCommissionTree = () => (dispatch) => {
  userAxios.get(`invester/user/referrals`).then((res) => {
    // Save to localStorage
    dispatch({
      type: GET_REFRAL_COMMISSION_TREE,
      payload: res?.data?.payload?.referral_users,
    });
  });
};

export const getRefralCommissionUserListLevel = (status) => (dispatch) => {
  userAxios.get(`/invester/user/referrals`).then((res) => {
    // Save to localStorage
    dispatch({
      type: GET_REFRAL_COMMISSION_USER_LEVEL,
      payload: res?.data?.payload,
    });
  });
};
export const intersetEarned = (status) => (dispatch) => {
  userAxios
    .get(`/invester/investment/get-interest-commission-list?status=${status}`)
    .then((res) => {
      console.log("Response data:", res?.data?.payload?.investment_interest_commissions);
      // Save to localStorage
      dispatch({
        type: GET_INTERSET_COMMISSION_USER,
        payload: res?.data?.payload?.investment_interest_commissions,
      });
    });
};

