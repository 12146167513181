import React, { useEffect, useState } from "react";
import Side_bar from "../Side_bar";
import "./InvestmentOverview.scss";
import NavBar from "../../NavBar";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  getInverstmentList,
  getListOfWithdraw,
  getOverview,
  getRefralCommissionUserList,
  intersetEarned,
  withDraw,
} from "../actions/investActions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";
import Modal from "react-responsive-modal";

const InvestmentOverview = () => {
  const dispatch = useDispatch();
  const { history } = useSelector((state) => state?.history);
  const {
    withdraw,
    investment_refral_commissions,
    investment_interest_commissions,
  } = useSelector((state) => state?.history);

  const { loading } = useSelector((state) => state?.loading);
  const { user } = useSelector((state) => state?.auth);
  const { overview } = useSelector((state) => state?.overview);
  const [response, setReponse] = useState([]);
  const [open, setOpen] = useState(false);
  const [popup, setPopup] = useState(null);

  useEffect(() => {
    dispatch(getInverstmentList());
    dispatch(getListOfWithdraw());
    dispatch(getRefralCommissionUserList("Approved"));
    dispatch(intersetEarned());
    dispatch(getOverview());
  }, []);

  useEffect(() => {
    if (
      history?.length > 0 ||
      withdraw?.length > 0 ||
      investment_interest_commissions?.length > 0 ||
      investment_refral_commissions?.length > 0
    ) {
      setReponse(
        [].concat(
          history.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
          withdraw.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ),
          investment_interest_commissions.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ),
          investment_refral_commissions.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        )
      );
    }
  }, [
    history,
    withdraw,
    investment_interest_commissions,
    investment_refral_commissions,
  ]);

  const handleDetails = (res) => {
    setOpen(true);
    setPopup({
      ...res,
    });
  };

  const getDate = (date) => {
    const currentDate = new Date(date);

    const readableDate = currentDate.toDateString();
    const time = currentDate.toLocaleTimeString();

    return readableDate ;
  };

  const { t } = useTranslation();

  const getTotalValue = () => {
    if (Object.keys(overview).includes("group_bonus")) {
      return (
        overview.refral_earned +
        overview.interest_earned +
        overview.group_bonus +
        overview.total_invest
      );
    } else {
      return (
        overview.refral_earned +
        overview.interest_earned +
        overview.total_invest
      );
    }
  };
  console.log("investment_interest_commissions:", investment_interest_commissions);

  const getGroupBonus = () => {
    if (Object.keys(overview).includes("group_bonus")) {
      return (
        <div className="w-25">
          <p className="light-txts text-center mb-1">{t("Group Bonus")}</p>
          <div className="both-shadow">
            <p className="text-center m-0">
              {loading ? (
                <Skeleton />
              ) : overview?.group_bonus ? (
                `USDT ${overview?.group_bonus}`
              ) : (
                0
              )}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <NavBar />
      <div className="user-section">
        <Side_bar />
        <div className="user-content">
          <div className="overview-section">
            <h1>{t("Investment Overview")}</h1>
            <div className="transaction-hx">
              <div className="trans-contents" style={{ height: "fit-content" }}>
                <div className="">
                  <h6 className="mb-3">
                    {t(`${user?.user?.user_name}'s Investment`)}
                  </h6>
                  <div className="inner-card">
                    <div className="trans-summary trans-sep">
                      <div>
                        <div>
                          <p className="light-txts text-center mb-1">
                            {t("Total Deposit")}
                          </p>
                          <div className="sgd both-shadow mx-auto">
                            <h5 className="m-0">
                              {t("USDT")}
                              <br />
                              {loading ? (
                                <Skeleton />
                              ) : overview?.total_invest > 0 ? (
                                <h5 className="m-0 text-center">
                                  {!user?.user?.isDummy
                                    ? overview?.total_invest
                                    : `0`}
                                </h5>
                              ) : (
                                <h5 className="m-0 text-center">{` 0`}</h5>
                              )}
                            </h5>
                          </div>
                        </div>
                        <div className="mt-3 w-120 mx-auto">
                          <p className="light-txts text-center mb-1">
                            {t("ROI")}
                          </p>
                          <div className="both-shadow">
                            <p className="text-center m-0">
                              {loading ? (
                                <Skeleton />
                              ) : overview?.interest_earned ? (
                                `USDT ${overview?.interest_earned}`
                              ) : (
                                0
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ref-content">
                      <div>
                        <p className="light-txts text-center mb-1">
                          {t("Referral Earned")}
                        </p>
                        <div className="both-shadow w-120">
                          <p className="text-center mb-0">
                            {loading ? (
                              <Skeleton />
                            ) : overview?.refral_earned ? (
                              `USDT ${overview?.refral_earned}`
                            ) : (
                              0
                            )}
                          </p>
                        </div>
                      </div>
                      {getGroupBonus()}
                      <div className="ref-id">
                        <p className="light-txts text-center mb-1">
                          {t("My Referral ID")}
                        </p>
                        <div className="both-shadow w-120">
                          <p className="text-center mb-0">
                            {user?.user?.refral_code}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="w-100">
                      <p className="light-txts text-center mb-1">
                        {t("Total Value")}
                      </p>
                      <div className="both-shadow">
                        <p className="text-center mb-0">
                          {!loading ? (
                            <>
                              {user?.user?.isDummy
                                ? `USDT ${
                                    overview?.interest_earned +
                                    overview?.refral_earned
                                  }`
                                : `USDT ${getTotalValue()}`}
                            </>
                          ) : (
                            <Skeleton />
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="trans-contents">
                <h6 className="mb-3">{t("History")}</h6>
                <div
                  className="inner-card custom-scroll"
                  style={{
                    overflow: "hidden",
                    overflowY: "scroll",
                    height: "auto",
                  }}
                >
                  <div className="recent-trans">
                    {response?.length > 0 ? (
                      response?.map((item) => (
                        <>
                          {item !== undefined && (
                            <div className="d-flex justify-content-between">
                              <p claclassNamess="light-txts">
                                <span>{item.user_id.type}</span>
                                <br />
                                <span>{getDate(item.createdAt)}</span>
                              </p>
                              <p className="light-txts">
                                <span>
                                  {t("USDT")} {item.amount}
                                </span>
                                <br />
                                <span>
                                  <b>{item.status}</b>
                                </span>
                              </p>
                            </div>
                          )}
                        </>
                      ))
                    ) : loading ? (
                      [...Array(10)].map(() => (
                        <p>
                          <Skeleton />
                        </p>
                      ))
                    ) : (
                      <>{t("No Record Found")}</>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="trans-content interest-earned-btn ref-table mt-1 mb-4">
  <h1>{t(" ROI Investments")}</h1>
  <div className="table-scroll">
  <Table responsive="md" hover>
  <thead>
    <tr>
      <th>Id</th>
      <th>Total Deposit</th>
      <th>Deposit Date</th>
      <th>ROI Amount</th>
      <th>Affected Date</th>
      <th>Season</th>
      <th>Status</th>
      <th>Dividend Received</th>
    </tr>
  </thead>
  <tbody>
    {investment_interest_commissions?.length > 0 &&
      investment_interest_commissions
        .sort((a, b) => new Date(a.affected_date) - new Date(b.affected_date)) // Sort in ascending order by affected_date
        .map((inv, index) => (
          <tr key={index}>
            <td>{index}</td>
            <td>{overview?.total_invest}</td>
            <td>{inv.createdAt}</td>
            <td>{inv.amount}</td>
            <td>{getDate(inv.affected_date)}</td>
            <td>{inv.reason}</td>
            <td>{inv.status}</td>
            <td>
              {inv.status === 'Available' ? (
                <span>&#10006;</span> // Cross icon
              ) : inv.status === 'Approved' ? (
                <span>&#10004;</span> // Tick icon
              ) : null}
            </td>
          </tr>
        ))}
  </tbody>
</Table>

   </div>
   </div>
   </div>
   </div>
   </div>
   </>
  );
};

export default InvestmentOverview;
