import React from "react";
const Input = ({ label, type, name, className, errors, register, ...rest }) => {
  return (
    <>
      <div
        className={
          className
            ? "country form-group mb-2 d-flex align-items-center mt-3"
            : "fields"
        }
      >
        <label className="text-nowrap">{label}</label>
        <input
          {...rest}
          {...register(name, {
            required: require,
          })}
          id={name}
          type={type}
          className="form-control"
        />
      </div>
      {errors && (
        <span className="fields" style={{ color: "red", marginTop: "-10px" }}>
          {errors && errors}
        </span>
      )}
    </>
  );
};
export default Input;
