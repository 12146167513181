import {
  GET_PAST_INVESTMENTS,
  GET_INVESTORS,
  GET_INVESTMENTS,
  GET_WITHDRAW,
  GET_REFRAL_COMMISSION,
  GET_INTEREST_COMMISSION,
  GET_PAST_WITHDRAW,
  GET_PAST_REFRAL_COMMISSIONS,
  GET_PAST_INTEREST_COMMSIONS,
} from "../../../utils/types";

const initialState = {
  investments: [],
  withdraw: [],
  investment_refral_commissions: [],
  investment_interest_commissions: [],
  pastInvestments: [],
  pastWithdraw: [],
  pastRefralCommissions: [],
  pastInterestCommissions: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INVESTMENTS:
      return {
        ...state,
        investments: action.payload,
      };
    case GET_WITHDRAW:
      return {
        ...state,
        withdraw: action.payload,
      };
    case GET_REFRAL_COMMISSION:
      return {
        ...state,
        investment_refral_commissions: action.payload,
      };
    case GET_INTEREST_COMMISSION:
      return {
        ...state,
        investment_interest_commissions: action.payload,
      };
    case GET_PAST_INVESTMENTS:
      return {
        ...state,
        pastInvestments: action.payload,
      };
    case GET_PAST_WITHDRAW:
      return {
        ...state,
        pastWithdraw: action.payload,
      };
    case GET_PAST_REFRAL_COMMISSIONS:
      return {
        ...state,
        pastRefralCommissions: action.payload,
      };
    case GET_PAST_INTEREST_COMMSIONS:
      return {
        ...state,
        pastInterestCommissions: action.payload,
      };
    default:
      return state;
  }
}
