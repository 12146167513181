//Authenticated User
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_LANG = "SET_CURRENT_LANG";
export const ALL_INVESTMENT = "ALL_INVESTMENT";
export const GET_WITHDRAW_LIST = "GET_WITHDRAW_LIST";
export const GET_OVERVIEW = "GET_OVERVIEW";
export const GET_REFRAL_COMMISSION_USER = "GET_REFRAL_COMMISSION_USER";
export const GET_INTERSET_COMMISSION_USER = "GET_INTERSET_COMMISSION_USER";
export const GET_REFRAL_COMMISSION_USER_LEVEL =
  "GET_REFRAL_COMMISSION_USER_LEVEL";
export const GET_REFRAL_COMMISSION_TREE = "GET_REFRAL_COMMISSION_TREE";
export const LOTS_NUMBERS = "LOTS_NUMBERS";
//Loading
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
//Errors
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//Authenticated Admin
export const SET_CURRENT_ADMIN = "SET_CURRENT_ADMIN";
export const GET_INVESTORS = "GET_INVESTORS";
export const REFRAL_TREE = "REFRAL_TREE";
export const REFRAL_HISTORY = "REFRAL_HISTORY";
export const INTERST_HISTORY = "INTERST_HISTORY";
export const GROUP_BONUS_HISTORY = "GROUP_BONUS_HISTORY";
export const GET_INVESTMENTS = "GET_INVESTMENTS";
export const GET_WITHDRAW = "GET_WITHDRAW";
export const GET_REFRAL_COMMISSION = "GET_REFRAL_COMMISSION";
export const GET_INTEREST_COMMISSION = "GET_INTEREST_COMMISSION";
export const GET_PAST_INVESTMENTS = "GET_PAST_INVESTMENTS";
export const GET_PAST_WITHDRAW = "GET_PAST_WITHDRAW";
export const GET_PAST_REFRAL_COMMISSIONS = "GET_PAST_REFRAL_COMMISSIONS";
export const GET_PAST_INTEREST_COMMSIONS = "GET_PAST_INTEREST_COMMSIONS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

//Notification texts
export const notificationMessages = {
  Registration: "User Registered",
};
