import { adminAxios } from "../../../config/config";
import setAdminData from "../../../utils/auth/setAdminData";
import { SET_CURRENT_ADMIN, SET_NOTIFICATIONS } from "../../../utils/types";
import { saveAs } from 'file-saver';
export const loginAdmin = (userData, history) => (dispatch) => {
  adminAxios
    .post(`/admin/user/login`, userData)
    .then((res) => {
      // Save to localStorage
      let user_auth_data = {};
      user_auth_data.token = res.headers["token"];
      // Set token to localStorage
      localStorage.setItem("admin_data", JSON.stringify(res.data.payload));
      dispatch(setCurrentAdmin(res.data.payload));
      setAdminData(user_auth_data);
      // Set current user
      history.push("/admin/investor-details");
      window.location.reload();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setCurrentAdmin = (userData) => {
  return {
    type: SET_CURRENT_ADMIN,
    payload: userData,
  };
};

export const setNotifications = (nots) => {
  return {
    type: SET_NOTIFICATIONS,
    payload: nots,
  };
};





// export const handleReportDownload = async () => {
//   try {
//     const response = await fetch('/admin/investment/download-report', {
//       method: 'GET'
//     });

//     if (!response.ok) {
//       throw new Error(`Failed to download file: ${response.statusText}`);
//     }

//     const blob = await response.blob();
//     console.log(`Blob size: ${blob.size}`); // Check the blob size

//     saveAs(blob, 'WeeklyReport.xlsx');
//   } catch (error) {
//     console.error('Error downloading the file:', error);
//     alert(`Error downloading the file: ${error.message}`);
//   }
// };

export const downloadFile = async () => {
  try {
      const response = await adminAxios.get('/admin/investment/download-report', {
          responseType: 'blob',
          headers: {
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'example.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
  } catch (error) {
      console.error('File download failed:', error);
  }
};

export const updateNotifications = (nots) => (dispatch) => {
  const notsToRead = nots.filter((not, _) => !not?.readStatus);
  const prevNots = nots;
  adminAxios
    .post("/admin/investment/update-notifications", {
      notificationIds: notsToRead,
    })
    .then((res) => {
      console.log(res?.data?.updatedNotifications);
      const updatedNots = res?.data?.updatedNotifications;
      updatedNots.forEach((updatedNot) => {
        let prevNot = prevNots.find((not) => not.id === updatedNot.id);
        if (prevNot) {
          prevNot.status = updatedNot.status;
        }
      });
      dispatch(setNotifications(prevNots));
    });
};

export const fetchNotifications = (setNots) => (dispatch) => {
  adminAxios
    .get("/admin/investment/fetch-notifications")
    .then((res) => {
      console.log(res.data.notifications);
      setNots(res?.data?.notifications);
      dispatch(setNotifications(res?.data?.notifications));
    })
    .catch((err) => {
      console.log("error fething notifications", err);
    });
};

export const logoutAdmin = (history) => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("admin_data");
  // Remove auth header for future requests
  setAdminData(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentAdmin({}));
};
