import { userAxios } from "../../../config/config";
import { showToast } from "../../../utils/toast";

export const signup = (userData, history, onlyCreate) => (dispatch) => {
  console.log("userData", userData);
  const obj = {
    user_name: userData?.user_name,
    first_name: userData?.first_name,
    last_name: userData?.last_name,
    email: userData?.email,
    password: userData?.password,
    phone_number: userData?.phone_number,
    refral_code: userData?.referral_number,
    subscription: userData?.subscription, // Include subscription data
  };
  userAxios
    .post(`/invester/user/register`, obj)
    .then((res) => {
      if (onlyCreate) {
        showToast("success", "Account Created Successfully.");
      } else {
        history.push("/user/login");
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const forgotUser = (userData, history) => (dispatch) => {
  const obj = {
    user_name: userData?.user_name,
  };
  userAxios.post(`/common/auth/forgot-password`, obj).then((res) => {
    showToast("success", res?.data?.message);
    history.push("/user/login");
  });
};
