import "./App.scss";
import { Route, Switch, } from "react-router-dom";
// import { withRouter } from "react-router";

import Landing_page from "./components/Landing_page";
/*import Referral_scheme from "./components/Referral_scheme";
import About_tom from "./components/About_tom";
import Blog from "./components/Blog";
import InvestorDeck from "./components/InvestorDeck";
import Blog_category from "./components/Blog_category";
import Blog_post from "./components/Blog_post";
import Invest_in_cannabis from "./components/Invest_in_cannabis";*/

import { Provider } from 'react-redux'
import store from "./store";
import UserRoutes from "./routes/user/UserRoutes";
import AdminRoutes from "./routes/admin/AdminRoutes";
import { withRouter } from "react-router-dom";
import { setCurrentUser } from "./components/UserPanel/actions/loginActions";
import setUserData from "./utils/auth/setUserData";
import { setCurrentAdmin } from "./components/AdminPanel/actions/adminLoginAction";
import setAdminData from "./utils/auth/setAdminData";

if (JSON.parse(localStorage.getItem('user_data'))) {
  // Set auth token header auth
  setUserData(JSON.parse(localStorage.getItem('user_data')));
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(JSON.parse(localStorage.getItem('user_data'))));
}

if (JSON.parse(localStorage.getItem('admin_data'))) {
  // Set auth token header auth
  setAdminData(JSON.parse(localStorage.getItem('admin_data')));
  // Set admin and isAuthenticated
  store.dispatch(setCurrentAdmin(JSON.parse(localStorage.getItem('admin_data'))));
}
function App() {
  return (
    <Provider store={store}>
      {/* <BrowserRouter> */}
      <Switch>
        <Route path="/user/:page?" component={UserRoutes} />
        <Route path="/admin/:page?" component={AdminRoutes} />
       <Route exact path="/" component={Landing_page} />
        {/*<Route path="/referral-scheme" component={Referral_scheme} />
        <Route path="/invest-in-cannabis" component={Invest_in_cannabis} />
        <Route path="/blog" component={Blog} />
        {/* <Route path="/investor-deck" component={InvestorDeck} /> */}

       {/* <Route path="/about-tom" component={About_tom} />
        <Route path="/blog-category" component={Blog_category} />
        <Route path="/blog-post" component={Blog_post} />*/}
      </Switch>
      {/* </BrowserRouter> */}
    </Provider>
  );
}

export default withRouter(App);
