import { yupResolver } from "@hookform/resolvers/yup";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../Common/Input";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { investAmountWithRP } from "../actions/investActions";
import { showToast } from "../../../utils/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const schema = yup.object().shape({
  amount: yup
    .number()
    .typeError("Amount is a required field. Amount must be a number")
    .required("Amount is a required field")
    .min(2800, "Too little"),
});

const InvestWithRP = ({ setError }) => {
  const { lots_details } = useSelector((state) => state.history);
  const { overview } = useSelector((state) => state?.overview);
  const { loading } = useSelector((state) => state.loading);

  //Disclaimer states
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const contentRef = useRef(null);

  // disclaimer events
  const handleCheckClick = (event) => {
    event.preventDefault();
    setShowDisclaimer(true);
  };
  const handleAccept = () => {
    setAccepted(true);
    setShowDisclaimer(false);
  };

  const handleReject = () => {
    setAccepted(false);
    setShowDisclaimer(false);
  };

  const handleClose = () => {
    setShowDisclaimer(false);
  };

  const handleScroll = () => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const scrolledToBottom =
        contentElement.scrollHeight - contentElement.scrollTop ===
        contentElement.clientHeight;
      setIsScrolledToBottom(scrolledToBottom);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();

  // form submit
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    values: { amount: 0, country: "" },
    resolver: yupResolver(schema),
  });
  const { amount } = watch();

  const _invest = (data) => {
    if (amount % 3 === 0) {
      setError(null);
      if (amount <= overview?.registration_point) {
        dispatch(investAmountWithRP(data, history));
        showToast("success", "Submitted");
      } else {
        showToast("error", "Not enough Registration Points");
        setError({ message: "Not enough Registration Points" });
      }
    } else {
      showToast("error", "Amount should be multiple of 3000.");
    }
  };

  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit((data) => _invest(data))}>
      <div className="d-flex flex-wrap mt-2">
        <p className="mr-3">{t("Registration Points")}:</p>
        <p className="wallet-address">
          <b>{overview?.registration_point}</b>
        </p>
      </div>
      <div className="d-flex flex-wrap mt-2">
        <p className="mr-3">{t("Total Lots")}:</p>
        <p className="wallet-address">
          <b>{lots_details?.total_lots}</b>
        </p>
      </div>

      <div className="flex">
        <Input
          label="Amount USDT:"
          errors={errors?.amount?.message}
          type="number"
          name="amount"
          register={register}
          className={true}
        />
      </div>

      <div className="terms-section gap-3">
        <label className="mt-0 mb-3">
          <input
            type="checkbox"
            required
            checked={accepted}
            onChange={handleCheckClick}
          />
        </label>
        <p>
          {t(
            "I agree to the terms and conditions stated in the Maconha Holding Investment Disclaimer"
          )}
        </p>

        {showDisclaimer && (
          <div className="popup-container">
            <div className="popup w-75">
              <div className="d-flex mb-3 justify-content-between">
                <h2>Maconha Holding Investment Disclaimer</h2>
                <button className="close-button" onClick={handleClose}>
                  &times;
                </button>
              </div>
              <div
                className="popup-content"
                ref={contentRef}
                onScroll={handleScroll}
              >
                <div className="scrollable-content">
                  <strong>Disclaimer Agreement</strong>
                  <p>
                    This Investment Disclaimer ("Agreement") is made and entered
                    into by and between you ("The Investor") and Maconha Holding
                    ("The Company"). By checking the "I agree" box, Investor
                    hereby acknowledges and agrees to the terms and conditions
                    set forth in this Agreement. The following provisions
                    outline the nature of the investment program and related
                    considerations. This Agreement is legally binding and serves
                    to clarify the responsibilities and rights of both parties.
                  </p>
                  <ul>
                    <li>
                      <strong>1. Investment Nature and Risks</strong>
                      <ul>
                        <li>
                          Investor acknowledges that investing in the cannabis
                          sector carries inherent risks, including but not
                          limited to financial losses, market volatility, and
                          regulatory uncertainties.
                        </li>
                        <li>
                          The company does not provide any guarantees or
                          warranties regarding the profitability, performance,
                          or outcome of the Investor's investment. Investors
                          shall bear all risks associated with their investment
                          decision.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>2. Return on Investment (ROI)</strong>
                      <ul>
                        <li>
                          Investor understands that the Company offers a
                          4-season (20 months) investment program with various
                          ROI options
                        </li>
                        <li>
                          Investor acknowledges that the Company's
                          representations regarding ROI are estimates and
                          projections, subject to the performance of cultivation
                          activities and market dynamics.
                        </li>
                        <li>
                          The investor acknowledges and agrees that the
                          investment period is fixed at 20 months.
                        </li>
                        <li>
                          Investor further acknowledges that they cannot
                          withdraw their principal investment until the
                          completion of the 20-month period.
                        </li>
                        <li>
                          Dividends, however, can be withdrawn on the 5th, 10th,
                          15th, and 20th months.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>3. Referral Programme</strong>
                      <ul>
                        <li>
                          Investor recognizes and accepts the innovative
                          structure of the referral programme.
                        </li>
                        <li>
                          Investor acknowledges that they can earn 15% of the
                          investment amount from direct referrals and 5% from
                          the investment amount of indirect referrals
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>4. Monthly Campaign</strong>
                      <ul>
                        <li>
                          The Company introduces a special Monthly Campaign
                          offer available for a limited period of 3 months.
                        </li>
                        <li>
                          The Monthly Campaign is designed to incentivize and
                          reward investors during the initial phase of the
                          program
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>5. Legal Compliance</strong>
                      <ul>
                        <li>
                          The Investor understands that their investment is
                          subject to relevant laws and regulations, both
                          domestically and internationally.
                        </li>
                        <li>
                          The company operates under valid licenses, adhering to
                          legal requirements for cannabis product sales.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>
                        6. Personal Responsibility and Acknowledgment
                      </strong>
                      <ul>
                        <li>
                          The Investor acknowledges and confirms that their
                          investment decision is based on a thorough assessment
                          and understanding of the risks.
                        </li>
                        <li>
                          The Investor acknowledges that Company is not
                          responsible for any financial losses or adverse
                          outcomes arising from their investment
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>7. Dispute Resolution</strong>
                    </li>
                  </ul>
                  <p>
                    In the event of any disputes arising under this Agreement,
                    both Investor and Company agree to resolve such disputes
                    through binding arbitration in accordance with the laws of
                    the jurisdiction where the Company is registered.
                  </p>
                  <ul>
                    <li>
                      <strong>8. Entire Agreement</strong>
                    </li>
                  </ul>
                  <p>
                    This Agreement constitutes the entire agreement between
                    Investor and Company regarding the investment program,
                    superseding any prior agreements or understandings, whether
                    oral or written.
                  </p>
                  <p>
                    By checking the "I agree" box, The Investor affirms that
                    they have read, understood, and agreed to the terms and
                    conditions set forth in this Agreement. If The Investor does
                    not agree to the terms stated herein, they must not proceed
                    with their investment.
                  </p>
                  <p>
                    This Agreement is executed as of the date of the Investor's
                    agreement.
                  </p>
                </div>
              </div>
              <div className="popup-buttons">
                <button
                  className="btn btn-danger mr-3"
                  onClick={handleReject}
                  disabled={!accepted}
                >
                  Reject
                </button>
                <button
                  className="btn btn-success"
                  onClick={handleAccept}
                  disabled={!isScrolledToBottom}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {!loading ? (
        <button type="submit" className="w-100">
          {t("Submit")}
        </button>
      ) : (
        <button type="button">
          <div className="loader"></div>
        </button>
      )}
    </form>
  );
};

export default InvestWithRP;
