import React, { useEffect, useState } from "react";
import "./TransactionHistory.scss";
import AdminNavBar from "../AdminNavBar";
import {
  getAllInvestments,
  getAllWithdraw,
  getAllRefralCommissionList,
  getAllInterestCommissionList,
} from "../actions/investorsActions";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";

import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";

const TransactionHistoy = () => {
  const dispatch = useDispatch();
  const {
    pastInvestments,
    pastWithdraw,
    pastRefralCommissions,
    pastInterestCommissions,
  } = useSelector((state) => state?.investments);
  const [transactionResponse, setTransactionReponse] = useState([]);
  const [commissionsResponse, setCommissionsReponse] = useState([]);
  useEffect(() => {
    dispatch(getAllInvestments());
    dispatch(getAllWithdraw());
    dispatch(getAllRefralCommissionList());
    dispatch(getAllInterestCommissionList());
  }, []);

  const option = [
    { label: "BSC", value: "0x17d091ade593c7404f558ffB21102Fa96e357164" },
    { label: "TRC", value: "TW1JNHYVBpw8hhSWVkasTMZfUoegTthD3T" },
    { label: "ERC", value: "0x238B40131C9760CDbCa61505C65429Ef62D7eBA3" },
  ];

  useEffect(() => {
    if (
      pastInvestments?.length > 0 ||
      pastWithdraw?.length > 0 ||
      pastInterestCommissions?.length > 0 ||
      pastRefralCommissions?.length > 0
    ) {
      const tResp = [].concat(pastInvestments, pastWithdraw);

      setTransactionReponse(
        tResp.map((item) => {
          const data = option.filter((obj) => obj.value === item?.network);
          return {
            ...item,
            network: <td>{data[0]?.label}</td>,
            name: item?.user_id?.user_name + " ",
            updatedAt:
              new Date(item?.updatedAt).toLocaleDateString() +
              new Date(item?.updatedAt).toLocaleTimeString(),
          };
        })
      );

      const cResp = [].concat(pastInterestCommissions, pastRefralCommissions);
      setCommissionsReponse(
        cResp.map((item) => {
          return {
            ...item,
            name: item?.user_id?.user_name + " ",
            type: item?.user_id?.type,
            network: item?.network,
            updatedAt:
              new Date(item?.updatedAt).toLocaleDateString() +
              new Date(item?.updatedAt).toLocaleTimeString(),
          };
        })
      );
    }
  }, [
    pastInvestments,
    pastInterestCommissions,
    pastWithdraw,
    pastRefralCommissions,
  ]);

  const transactionColumns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "tax_hash",
      label: "Trx Hash",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "network",
      label: "Netwok",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "updatedAt",
      label: "Updated At",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const commissionsColumns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "updatedAt",
      label: "Updated At",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  // const options = {
  //   filterType: 'checkbox',
  // };
  const { t, i18n } = useTranslation();
  console.log(t);
  return (
    <>
      <AdminNavBar />
      <div className="transactions-section">
        <div className="transactions-content">
          <h2>{t("Transactions History")}</h2>
          <MUIDataTable
            title={"Transactions"}
            data={transactionResponse}
            columns={transactionColumns}
            // options={options}
          />
          {/* <div id="loader" className="loader-overlay">
              <div className="loader-box">
                <div id="preLoader" />
              </div>
            </div> */}
        </div>
        <div className="transactions-content mt-5">
          <h2>{t("Commissions History")}</h2>
          <MUIDataTable
            title={"Commissions"}
            data={commissionsResponse}
            columns={commissionsColumns}
            // options={options}
          />
          {/* <div id="loader" className="loader-overlay">
              <div className="loader-box">
                <div id="preLoader" />
              </div>
            </div> */}
        </div>
        {/* <div className="transactions-content">
          <h2>Transactions</h2>
          <div className="outer-shadow"></div>
          <div className="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Transaction</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              {response?.length > 0 ?
                response?.map(item => (
                  <tbody>
                    <tr>
                      <td>{item?.user_id?.first_name + "" + item?.user_id?.last_name}</td>
                      <td>{item?.user_id?.type}</td>
                      <td>{item?.amount}</td>
                      <td>
                        <a style={{ marginRight: '5px' }} onClick={() => updateStatus(item, item?.account_no ? 'Paid' : 'Approved')}>
                          Approve /
                        </a>
                        <a onClick={() => updateStatus(item, 'Rejected')}>
                          Reject
                        </a>
                      </td>
                    </tr>
                  </tbody>)) :
                loading ?
                  [...Array(10)].map(() => (
                    <tbody>
                      <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                      </tr>
                    </tbody>
                  )) :
                  <div>
                    No Record Found
                  </div>
              }
            </table>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default TransactionHistoy;
