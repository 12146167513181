import isEmpty from "../../../utils/isEmpty";
import { SET_CURRENT_ADMIN, SET_NOTIFICATIONS } from "../../../utils/types";

const initialState = {
  isAuthenticated: false,
  notifications: [],
  admin: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ADMIN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        admin: action.payload,
      };
    case SET_NOTIFICATIONS:
      console.log(action.payload);
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
}
