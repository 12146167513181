import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MyProfile from "../../components/UserPanel/MyProfile/MyProfile";
import UserPrivateRoute from "./UserPrivateRoute";
import InvestmentOverview from "../../components/UserPanel/InvestmentOverview/InvestmentOverview";
import Invest from "../../components/UserPanel/Invest/Invest";
import RefferalCommission from "../../components/UserPanel/RefferalCommission";
import WithdrawFunds_2 from "../../components/UserPanel/WithdrawFunds_2";
import User_Signup from "../../components/UserPanel/UserSignup/User_Signup";
import UserForgot from "../../components/UserPanel/UserLogin/UserFogot";
import User_Login from "../../components/UserPanel/UserLogin/User_Login";
import Footer from "../../components/Footer";
import { withRouter } from "react-router-dom";
import User_Reset from "../../components/UserPanel/UserLogin/User_Reset";

const UserRoutes = () => {
  return (
    <Switch>
      <UserPrivateRoute path="/user/my-profile" component={MyProfile} />
      <UserPrivateRoute
        path="/user/investment-overview"
        component={InvestmentOverview}
      />
      <UserPrivateRoute path="/user/invest" component={Invest} />
      <UserPrivateRoute path="/user/incomes" component={RefferalCommission} />
      <UserPrivateRoute
        path="/user/withdraw-funds-2"
        component={WithdrawFunds_2}
      />

      <Route path="/user/login" component={User_Login} />
      <Route path="/user/signup" component={User_Signup} />
      <Route path="/user/forgot" component={UserForgot} />
      <Route path="/user/reset" component={User_Reset} />
      <Redirect from="/user" to="/user/login" />
      <Footer />
    </Switch>
  );
};

export default withRouter(UserRoutes);
