import React, { useState, useEffect } from "react";
import AdminNavBar from "../AdminNavBar";
import { useTranslation } from "react-i18next";
import Input from "../../Common/Input";
import "../AdminLogin/Admin_Login.scss";
import { adminAxios } from "../../../config/config";
import { CircularProgress, Spinner } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import Selector from "../../Common/selector"; // Adjust the import path
import { res } from "../../../utils/dummyRef";
import ReferralTree from "../../UserPanel/ReferralTree";
import { useDispatch, useSelector } from "react-redux";
import { userRefralTree } from "../actions/investorsActions";
import Skeleton from "react-loading-skeleton";

const UserDetails = (props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState("");
  const [userNames, setUserNames] = useState([]);
  const { refralTree } = useSelector((state) => state?.investors);
  const { t, i18n } = useTranslation();

  const currentUser = useSelector((state) => state?.adminAuth?.admin?.user);

  // const fetchUserNames = async () => {
  //   const url =  currentUser?.user_name === "admin" ? "/admin/investment/get-users" : `/admin/investment/get-users/${currentUser?.user_name}`;
  //   try {
  //     const response = await adminAxios.get("/admin/investment/get-users");
  //     setUserNames(response.data);
  //   } catch (error) {
  //     console.error("Error fetching user names:", error);
  //   }
const token = JSON.parse(localStorage.getItem("admin_data"))?.token || JSON.parse(localStorage.getItem("admin_data"))?.admin?.token;
  const fetchUserNames = async () => {
  try {
    let response;
    if (currentUser?.user_name === "admin") {
      response = await adminAxios.get("/admin/investment/get-users");
    } else if (currentUser?.user_name !== "admin") {
response = await adminAxios.post("admin/investment/get-refralls", { user_id: currentUser?._id }, {
  headers: {
    token: token,
  }
});    }

    if (response) {
  if (currentUser?.user_name !== "admin") {
    const names = Object.values(response.data.payload.referral_users)
      .flat()
      .map(user => user.name);
    console.log("🚀 ~ fetchUserNames ~ names:", names)
    setUserNames(names);
  } else {
    setUserNames(response.data);
  }
}
  } catch (error) {
    console.error("Error fetching user names:", error);
  }


  };
  const getDate = (date) => {
    const currentDate = new Date(date);

    const readableDate = currentDate.toDateString();
    // const time = currentDate.toLocaleTimeString();

    return readableDate;
  };

  // Call fetchUserNames function when the component mounts
  useEffect(() => {
    fetchUserNames();
  }, []);
  const getDetails = () => {
    setLoading(true);
    setError("");
    if (!userName) {
      setError("Please enter a user name.");
      setLoading(false);
      return;
    }

    adminAxios
      .post(`/admin/investment/get-usercommission-history`, {
        user_name: userName,
      })
      .then((res) => {
        console.log("API Response:", res.data);
        const userData = res.data.data;
        if (userData) {
          setUserDetails(userData);
          dispatch(userRefralTree(userData.user_id));
        } else {
          setError("No user details found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
        setError("Error fetching user details. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  console.log("chandamughal userName strongcase", userName)

  const [userRefferelDetails, setUserRefferelDetails] = useState([]);
  const [userRefferelWithdrawals, setUserRefferelWithdrawals] = useState([]);
  const [userGroupBonusWithdrawals, setUserGroupBonusWithdrawals] = useState(
    []
  );
  const [userInvestmentDetails, setUserInvestmentDetails] = useState([]);
  const [userInterestDetails, setUserInterestDetails] = useState([]);
  const [userInterestWithdrawals, setUserInterestWithdrawals] = useState([]);

  const [userRPWithdrawals, setUserRPWithdrawals] = useState([]);
  const [userGBWithdrawals, setUserGBWithdrawals] = useState([]);

  useEffect(() => {
    if (userDetails?.referralDetails?.transactions?.length > 0) {
      const modedTrx = userDetails?.referralDetails?.transactions?.map(
        (trx) => ({
          ...trx,
          createdAt: getDate(trx.createdAt),
          updatedAt: getDate(trx.updatedAt),
        })
      );

      setUserRefferelDetails(modedTrx);
    }
    if (userDetails?.groupBonusDetails?.withdrawals?.length > 0) {
      const modedTrx = userDetails?.groupBonusDetails?.withdrawals?.map(
        (trx) => ({
          ...trx,
          createdAt: getDate(trx.createdAt),
          updatedAt: getDate(trx.updatedAt),
        })
      );

      setUserGroupBonusWithdrawals(modedTrx);
    }
    if (userDetails?.referralDetails?.withdrawals?.length > 0) {
      const modedTrx = userDetails?.referralDetails?.withdrawals?.map(
        (trx) => ({
          ...trx,
          createdAt: getDate(trx.createdAt),
          updatedAt: getDate(trx.updatedAt),
        })
      );

      setUserRefferelWithdrawals(modedTrx);
    }

    if (userDetails?.investmentDetails?.transactions?.length > 0) {
      const modedTrx = userDetails?.investmentDetails?.transactions?.map(
        (trx) => ({
          ...trx,
          createdAt: getDate(trx.createdAt),
          updatedAt: getDate(trx.updatedAt),
        })
      );

      setUserInvestmentDetails(modedTrx);
    }

    if (userDetails?.interestDetails?.transactions?.length > 0) {
      const modedTrx = userDetails?.interestDetails?.transactions?.map(
        (trx) => ({
          ...trx,
          affected_date: getDate(trx.affected_date),
          createdAt: getDate(trx.createdAt),
          updatedAt: getDate(trx.updatedAt),
        })
      );

      setUserInterestDetails(modedTrx);
    }
    if (userDetails?.interestDetails?.withdrawals?.length > 0) {
      const modedTrx = userDetails?.interestDetails?.withdrawals?.map(
        (trx) => ({
          ...trx,
          createdAt: getDate(trx.createdAt),
          updatedAt: getDate(trx.updatedAt),
        })
      );

      setUserInterestWithdrawals(modedTrx);
    }

    if (userDetails?.registrationPointWithdrawals?.length > 0) {
      const modedTrx = userDetails?.registrationPointWithdrawals?.map(
        (trx) => ({
          ...trx,
          createdAt: getDate(trx.createdAt),
          updatedAt: getDate(trx.updatedAt),
        })
      );

      setUserRPWithdrawals(modedTrx);
    }
    if (userDetails?.userGroupbonusWithdrawls?.length > 0) {
      const modedTrx = userDetails?.userGroupbonusWithdrawls?.map((trx) => ({
        ...trx,
        createdAt: getDate(trx.createdAt),
        updatedAt: getDate(trx.updatedAt),
      }));

      setUserGBWithdrawals(modedTrx);
    }
  }, [userDetails]);

  const withdrawalColumns = [
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "network",
      label: "Network",
    },
    {
      name: "wallet_address",
      label: "Wallet Address",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "createdAt",
      label: "Created At",
    },
    {
      name: "updatedAt",
      label: "Updated At",
    },
  ];

  const referralDetailsColumns = [
    {
      name: "investment_id",
      label: "Investment ID",
    },
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "percentage",
      label: "Percentage",
    },
    {
      name: "reason",
      label: "Reason",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "account_name",
      label: "Account Name",
    },
    {
      name: "createdAt",
      label: "Created At",
    },
    {
      name: "updatedAt",
      label: "Updated At",
    },
  ];
  const interestDetailsColumns = [
    {
      name: "investment_id",
      label: "Investment ID",
    },
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "percentage",
      label: "Percentage",
    },
    {
      name: "reason",
      label: "Reason",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "affected_date",
      label: "Affected Date",
    },
    {
      name: "createdAt",
      label: "Created At",
    },
    {
      name: "updatedAt",
      label: "Updated At",
    },
  ];
  const investmentDetailsColumns = [
    {
      name: "type",
      label: "Type",
    },
    {
      name: "network",
      label: "Network",
    },
    {
      name: "tax_hash",
      label: "Transaction Hash",
    },
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "createdAt",
      label: "Created At",
    },
    {
      name: "updatedAt",
      label: "Updated At",
    },
  ];

  return (
    <>
      {loading && <Spinner />}
      <AdminNavBar />

      {error && <div>{error}</div>}

      <div className="investor-section">
        <div className="investor-content">
          <div className="d-flex px-3 py-2 flex-column m-auto gap-3 align-items-center border-0 w-50">
            <h2>{t("Get User Details")}</h2>
            <Selector
              label={``}
              name="user_name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              defaultOption="Select User"
              options={userNames.map((name) => ({ label: name, value: name }))}
              register={() => {}} // Placeholder function since register is not used here
            />
            <div className="login-btn">
              <button onClick={getDetails}>{t("Get")}</button>
            </div>
          </div>

          {userDetails && (
            <>
              {/* user account details  */}
              <div className="investor-section">
                <div className="investor-content">
                  <h2>{t("User Info")}</h2>
                  <div className="mt-3 d-flex flex-column gap-5 text-center">
                    <div className="d-flex flex-row col-lg-3 w-100 justify-content-around align-items-center">
                      <div className="w-100 d-flex flex-column gap-1">
                        <h5 className="mb-0">userGroupBonus</h5>
                        <p className="mb-0">{userDetails.userGroupBonus}</p>
                      </div>
                      <div className="w-100">
                        <h5 className="mb-0">SignUp Date</h5>
                        <p className="mb-0">{userDetails.signUpDate}</p>
                      </div>
                      <div className="w-100">
                        <h5 className="mb-0">Total Registration Points</h5>

                        <p className="mb-0">
                          {userDetails.totalRegistrationPoints}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-around align-items-center">
                      <div className="w-100">
                        <h5 className="mb-0">User ID</h5>
                        <p className="mb-0">{userDetails.user_id}</p>
                      </div>
                      <div className="w-100">
                        <h5 className="mb-0">Total Withdrawals</h5>
                        <p className="mb-0">{userDetails.totalWithdrawals}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* user account details  */}
              <div className="investor-section">
                <div className="investor-content">
                  <h2>{t("Referral Tree")}</h2>
                  <div className="p-2" style={{ borderRadius: "23px" }}>
                    <ReferralTree
                      user={userDetails?.user_id}
                      data={refralTree}
                      level={0}
                    />
                  </div>
                </div>
              </div>

              {/* user RP withdrawals  */}
              <div className="investor-section">
                <div className="investor-content">
                  <h2>{t("Withdrawal Detail")}</h2>
                  <MUIDataTable
                    title={"Registration Point Withdrawals"}
                    data={userRPWithdrawals}
                    columns={withdrawalColumns}
                  />
                </div>
              </div>

              {/* user referral record  */}
              <div className="investor-section">
                <div className="investor-content">
                  <h2>{t("Referral Details")}</h2>
                  <div className="d-flex flex-row gap-3 mb-3 w-100 justify-content-center align-items-center">
                    <div className="d-flex flex-column text-center">
                      <h5 className="mb-0">Total Earned</h5>
                      <p className="mb-0">
                        {userDetails?.referralDetails?.totalEarned.toFixed(2)}
                      </p>
                    </div>
                    <div className="d-flex flex-column text-center">
                      <h5 className="mb-0">Remaining Earned</h5>
                      <p className="mb-0">
                        {userDetails?.referralDetails?.remainingEarned.toFixed(
                          2
                        )}
                      </p>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Transactions"}
                    data={userRefferelDetails}
                    columns={referralDetailsColumns}
                  />
                  <br />
                  <MUIDataTable
                    title={"Withdrawals"}
                    data={userRefferelWithdrawals}
                    columns={withdrawalColumns}
                  />
                </div>
              </div>

              {/* user Group Bonus record  */}
              <div className="investor-section">
                <div className="investor-content">
                  <h2>{t("Group Bonus Details")}</h2>
                  <div className="d-flex flex-row gap-3 mb-3 w-100 justify-content-center align-items-center">
                    <div className="d-flex flex-column text-center">
                      <h5 className="mb-0">Total Earned</h5>
                      <p className="mb-0">
                        {userDetails?.groupBonusDetails?.totalEarned.toFixed(2)}
                      </p>
                    </div>
                    <div className="d-flex flex-column text-center">
                      <h5 className="mb-0">Remaining Earned</h5>
                      <p className="mb-0">
                        {userDetails?.groupBonusDetails?.remainingEarned.toFixed(
                          2
                        )}
                      </p>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Withdrawals"}
                    data={userGroupBonusWithdrawals}
                    columns={withdrawalColumns}
                  />
                </div>
              </div>

              {/* user investment record */}
              <div className="investor-section">
                <div className="investor-content">
                  <h2>{t("Investment Details")}</h2>
                  <div className="d-flex flex-row gap-3 mb-3 w-100 justify-content-center align-items-center">
                    <div className="d-flex flex-column text-center">
                      <h5 className="mb-0">Total Invested</h5>
                      <p className="mb-0">
                        {userDetails?.investmentDetails?.totalInvested?.toFixed(
                          2
                        )}
                      </p>
                    </div>
                    <div className="d-flex flex-column text-center">
                      <h5 className="mb-0">Remaining Invested</h5>
                      <p className="mb-0">
                        {userDetails?.investmentDetails?.remainingInvested?.toFixed(
                          2
                        )}
                      </p>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Transactions"}
                    data={userInvestmentDetails}
                    columns={investmentDetailsColumns}
                  />
                </div>
              </div>

              {/* user interest record  */}
              <div className="investor-section">
                <div className="investor-content">
                  <h2>{t("Interest Details")}</h2>
                  <div className="d-flex flex-row w-100 gap-3 mb-3 justify-content-center align-items-center">
                    <div className="d-flex flex-column text-center">
                      <h5 className="mb-0">Total Earned</h5>
                      <p className="mb-0">
                        {userDetails?.interestDetails?.totalEarned?.toFixed(2)}
                      </p>
                    </div>
                    <div className="d-flex flex-column text-center">
                      <h5 className="mb-0">Remaining Earned</h5>
                      <p className="mb-0">
                        {userDetails?.interestDetails?.remainingEarned?.toFixed(
                          2
                        )}
                      </p>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Transactions"}
                    data={userInterestDetails}
                    columns={interestDetailsColumns}
                  />
                  <br />
                  <MUIDataTable
                    title={"Withdrawals"}
                    data={userInterestWithdrawals}
                    columns={withdrawalColumns}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* {userDetails && (
        <div className="justify-content-center">
          <h3 className="m-auto"> User Details:</h3>
          <p className="mx-5">
            Total Registration Points: {userDetails.totalRegistrationPoints}
          </p>
          <p className="mx-5">Total Interest: {userDetails.totalInterest}</p>
          <p className="mx-5">
            Total Withdrawals: {userDetails.totalWithdrawals}
          </p>
          <p className="mx-5">Net Balance: {userDetails.netBalance}</p>

          <h3 className="m-auto">Withdrawal Details:</h3>
          <MUIDataTable
            title={"Withdrawal Details"}
            data={userDetails.withdrawalDetails}
            columns={RWColumns}
          />
        </div>
      )} */}
    </>
  );
};

export default UserDetails;
