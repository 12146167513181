import { userAxios } from "../../../config/config";
import setUserData from "../../../utils/auth/setUserData";
import { showToast } from "../../../utils/toast";
import { setCurrentUser } from "./loginActions";

export const profile_update = (userData, history) => (dispatch) => {
  const obj = {
    email: userData?.email,
    phone_number: userData?.phone_number,
  };
  userAxios
    .post(`/invester/user/update-profile`, obj)
    .then((res) => {
      showToast("success", "Profile updated successfully");
      // let user_auth_data = {};
      // user_auth_data.token = res.headers["access-token"];
      // user_auth_data.expiry = res.headers["expiry"];
      // user_auth_data.uid = res.headers["uid"];
      // user_auth_data.token_type = res.headers["token-type"];
      // Set token to localStorage
      localStorage.setItem("user_data", JSON.stringify(res.data.payload));
      // setUserData(user_auth_data);
      // Set current user
      dispatch(setCurrentUser(res.data.payload));
      history.push("/user/my-profile");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const password_update = (userData, history) => (dispatch) => {
  const obj = {
    new_password: userData?.newPassword,
    confirm_password: userData?.confirmNewPassword,
  };
  userAxios
    .post(`/common/auth/change-password`, obj)
    .then((res) => {
      showToast("success", res?.data?.message);
      history.push("/user/login");
    })
    .catch((error) => {
      console.log(error);
    });
};
