import React, { useEffect, useState } from "react";
import "./User_Login.scss";
import UserNavBar from "../UserPanelNavBar";
import Input from "../../Common/Input";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { login } from "../../UserPanel/actions/loginActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const schema = yup.object().shape({
  user_name: yup.string().required("User Name is a required field."),
  password: yup.string().required("Password is a required field."),
});

const User_Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state) => state.loading);
  const { backend_errors } = useSelector((state) => state.error);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: { user_name: "", password: "" },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (backend_errors) {
      setError(backend_errors);
    }
  }, [backend_errors]);

  const _login = (data) => {
    setError(null);
    data.user_name = data.user_name.toLowerCase();
    dispatch(login(data, history));
  };

  const { t } = useTranslation();

  return (
    <>
      <UserNavBar />
      <form onSubmit={handleSubmit((data) => _login(data))}>
        <div className="user-login mt-5">
          <div className="login-card">
            <div className="login-section">
              <h2>{t("LOGIN")}</h2>
              {error?.message && (
                <span className="fields" style={{ color: "red" }}>
                  {error?.message && error?.message}
                </span>
              )}
              <Input
                label={t("User Name")}
                errors={errors?.user_name?.message}
                type="user_name"
                name="user_name"
                register={register}
              />
              <div className="password-field" style={{ position: "relative" }}>
                <Input
                  label={t("Password")}
                  errors={errors?.password?.message}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  register={register}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="toggle-password-visibility"
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
              <div className="login-btn">
                {!loading ? (
                  <button type="submit">{t("LOGIN")}</button>
                ) : (
                  <button type="button">
                    <div className="loader"></div>
                  </button>
                )}
              </div>
              <div className="optional-actions">
                <div>
                  <a href="/user/signup">{t("Sign Up")}</a>
                </div>
                <div>
                  <a href="/user/forgot">{t("Forget Password")}?</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default User_Login;
